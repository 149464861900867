import React, { useState, useRef } from 'react';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';

function GenerateFirstDraftSBIR({
  onFirstDraftCreated,
  apiKey,
  setSelectedTab,
  setStreamedContent,
  companyName,
  outlineHeaders,
  outlineTitle,
  setOutlineContent,
  proposalProcessName,
  setFirstDraftSteaming,
  userID,
  volumeTag,
  rfpFile,
  numProposals,
  selectedRefFiles,
  selectedTopics,
  handleSave,
  setShowAlert,       // For general alerts
  setShowErrorAlert,  // For error-specific alerts
  aiOutlinePromptMessage,
  groupId,
  setIsGeneratingFirstDraftPopup,
  setCurrentFirstDraftStep
}) {
  const [isLoading, setIsLoading] = useState(false);
  const loadingBarRef = useRef(null);

  const ENV = process.env.NODE_ENV;
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

  const selectedFilesArray = Object.entries(selectedRefFiles)
    .filter(([_, isSelected]) => isSelected)
    .map(([fileName, _]) => fileName);

  const handleReadContent = async () => {
    console.log("selected file rfp - ", rfpFile);
    console.log("selected ref files - ", selectedFilesArray);
    console.log("selected topics - ", selectedTopics);

    setShowAlert(true); // Reset alert state in the parent
    setShowErrorAlert(false); // Reset error state in the parent
    setIsGeneratingFirstDraftPopup(true);
    setCurrentFirstDraftStep(0);

    if (handleSave) {
      console.log("Calling handleSave...");
      try {
        handleSave();
        console.log("handleSave executed successfully.");
      } catch (error) {
        console.error("Error during handleSave execution:", error);
        return;
      }
    } else {
      console.warn("handleSave is not provided or not a function.");
    }

    setStreamedContent('');
    setSelectedTab('viewGenerationFirstDraft');
    setFirstDraftSteaming(true);
    if (loadingBarRef.current) {
      loadingBarRef.current.continuousStart();
    }

    try {
      await generateProposalWithRetry();
    } catch (error) {
      console.error('Error processing proposal after retry:', error);
      setShowErrorAlert(true); // Notify the parent to show the error alert
      setSelectedTab('viewOutline'); // Set the tab to viewOutline
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
      setIsLoading(false);
    }
  };

  const generateProposalWithRetry = async (retryCount = 1) => {
    try {
      const formData = new FormData();
      formData.append('outlineHeaders', outlineHeaders);
      formData.append('outlineTitle', outlineTitle);
      console.log("outline title - ", outlineTitle)
      formData.append('userID', userID);
      formData.append('rfpFile', rfpFile);
      formData.append('selectedTopics', JSON.stringify(selectedTopics));
      formData.append('aiOutlinePromptMessage', JSON.stringify(aiOutlinePromptMessage));
      console.log("aiOutlinePromptMessage - ", aiOutlinePromptMessage);
      formData.append('groupID', groupId);

      selectedFilesArray.forEach(fileName => {
        formData.append('selectedRefFiles', fileName);
      });
      setOutlineContent(outlineHeaders);

      const streamResponse = await fetch(`${BASE_URL}/govex/generate-proposal-stream-sbir`, {
        method: 'POST',
        headers: {
          'X-API-KEY': apiKey,
          'X-Company-Name': companyName
        },
        body: formData,
      });

      if (!streamResponse.ok) {
        throw new Error(`Stream response was not ok: ${streamResponse.statusText}`);
      }

      const reader = streamResponse.body.getReader();
      const decoder = new TextDecoder();
      let completeProposalContent = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          console.log("Streaming complete");
          break;
        }
        const chunk = decoder.decode(value, { stream: true });
        completeProposalContent += chunk;
        setCurrentFirstDraftStep(6);
      

        setTimeout(() => {
          setIsGeneratingFirstDraftPopup(false);
        }, 4000);
        setStreamedContent((prevContent) => prevContent + chunk);
      }

      const finalizeResponse = await fetch(`${BASE_URL}/govex/finalize-proposal`, {
        method: 'POST',
        headers: {
          'X-API-KEY': apiKey,
          'Content-Type': 'application/json',
          'X-Group-ID': groupId
        },
        body: JSON.stringify({ proposalContent: completeProposalContent }),
      });

      if (!finalizeResponse.ok) {
        throw new Error(`Finalize response was not ok: ${finalizeResponse.statusText}`);
      }

      const finalizeData = await finalizeResponse.json();

      axios.post(`${BASE_URL}/govex/convert`, completeProposalContent, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'text/plain',
          'X-Proposal-Name': proposalProcessName,
        },
      })
        .then((response) => {
          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'download.docx'; // Default filename if not found
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
            if (fileNameMatch && fileNameMatch.length > 1) {
              fileName = fileNameMatch[1];
            }
          }

          const blob = new Blob([response.data]);
          const file = new File([blob], fileName, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          const documentFile = file;

          onFirstDraftCreated(completeProposalContent, finalizeData.new_num_proposals, documentFile, volumeTag, outlineHeaders);
        })
        .catch((error) => {
          console.error('Error downloading the document:', error);
        });

    } catch (error) {
      if (retryCount > 0) {
        console.warn('Retrying proposal generation due to error:', error);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Delay before retrying
        await generateProposalWithRetry(retryCount - 1); // Retry once
      } else {
        throw error; // If retry fails, throw the error
        setIsGeneratingFirstDraftPopup(false);
        setCurrentFirstDraftStep(0);
      }
    }
  };

  return (
    <div>
      <button onClick={handleReadContent} disabled={isLoading || (numProposals === 0)}>
        {isLoading ? 'Generating...' : (numProposals === 0 ? 'You are out of tokens. Contact us to purchase more' : 'Create a new draft based on this outline')}
      </button>
      <LoadingBar color="#f11946" ref={loadingBarRef} />
    </div>
  );
}

export default GenerateFirstDraftSBIR;
