import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Dashboard.css';
import Keycloak from 'keycloak-js';
import { useDropzone } from 'react-dropzone';
import xmlFormatter from 'xml-formatter';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import Editor from './Editor';
import Sidebar from './Sidebar';
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ConvertFirstDraftFromMarkdown from "./plugins/ConvertFirstDraftFromMarkdown";
import UpdateTextContent from "./plugins/UpdateTextContent";
import GenerateFullOutline from "./plugins/GenerateFullOutline";
import GenerateFirstDraft from "./plugins/GenerateFirstDraft";
import GenerateFirstDraftWithLexical from "./plugins/GenerateFirstDraftWithLexical";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { $createTextNode, $createParagraphNode, $getRoot } from 'lexical';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import {$createListItemNode, $createListNode} from '@lexical/list';
import {$createLinkNode} from '@lexical/link';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposer";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';

import FloatingToolbar from "./plugins/FloatingToolbar";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import StepIndicator from './StepIndicator';
import HowToUseGovExAI from './HowToUseGovExAI';
import ReferralPage from './ReferralPage';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


import AISidebar from "./plugins/AISidebar"

import { jwtDecode } from 'jwt-decode';
import ReactMarkdown from 'react-markdown';

import GenerateComplianceMatrix from "./plugins/GenerateComplianceMatrix";
import GenerateProposalStructure from "./plugins/GenerateProposalStructure";

import remarkGfm from 'remark-gfm'

import * as XLSX from 'xlsx';

// NODE_ENV=test in .env
const ENV = process.env.NODE_ENV; // 'test' or 'live'

// Select the URL based on the environment
const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;


////console.log(`Current NODE_ENV: ${process.env.NODE_ENV}`);
////console.log(`REACT_APP_TEST_URL: ${process.env.REACT_APP_TEST_URL}`);
////console.log(`REACT_APP_LIVE_URL: ${process.env.REACT_APP_LIVE_URL}`);
////console.log(`Resolved BASE_URL: ${BASE_URL}`);




let initOptions = {
  url: 'https://keycloak.govexai.com/auth',
  realm: 'GovEx',
  clientId: 'GovExClient',
  onLoad: 'login-required',
  KeycloakResponseType: 'code',
}

let kc = new Keycloak(initOptions);

function MyCustomAutoFocusPlugin({ onTriggerFocus }) {
  ////console.log("hit");


  return null;
}

function Dashboard() {
  const [selectedTab, setSelectedTab] = useState('nameProposal'); // manageDatabase | generateProposal | aiProposals | viewAnalysis | nameProposal
  const [selectedFile, setSelectedFile] = useState(null);
  const [excelFileSelected, setExcelFileSelected] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [selectedPreviousFile, setSelectedPreviousFile] = useState(null);
  const [isAddingProduct, setIsAddingProduct] = useState(true);
  const [productName, setProductName] = useState('');
  const [proposalProcessName, setProposalProcessName] = useState('');
  const [proposalProcessNameInText, setProposalProcessNameInText] = useState('');
  const [productFiles, setProductFiles] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [products, setProducts] = useState([]);
  const [proposalProcesses, setProposalProcesses] = useState([]);

  const [keycloakToken, setKeyCloakToken] = useState('');

  const [isAICollapsed, setIsAICollapsed] = useState(true);
  

  const [outlineContent, setOutlineContent] = useState('');
  const [showEditor, setShowEditor] = useState(false);
  const [editedOutlineHeaders, setEditedOutlineHeaders] = useState('');
  const [outlineHeadersGenerated, setOutlineHeadersGenerated] = useState(false);
  const [isEditorVisible, setIsEditorVisible] = useState(false);


  const [outlineFirstDraftMarkdown, setOutlineFirstDraftMarkdown] = useState('');
  const [outlineFirstDraftConverted, setFirstDraftConverted] = useState('');
  const [firstDraftGenerated, setFirstDraftGenerated] = useState(false);
  const [firstDraftStreaming, setFirstDraftSteaming] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [isAISidebarOpen, setIsAISidebarOpen] = useState(true);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const [selectedRefFiles, setSelectedRefFiles] = useState({});
  const [showCheckList, setShowCheckList] = useState(false);

  const [groupId, setGroupId] = useState(null);


  const [selectedText, setSelectedText] = useState('');
  const steps = [
    { key: 'nameProposal', label: 'Select Workflow', completed: false },
    { key: 'manageDatabase', label: 'Manage Database', completed: false },
    { key: 'generateProposal', label: 'Upload RFP/RFI/RFQ', completed: false },
    { key: 'viewComplianceMatrix', label: 'Compliance Matrix', completed: false },
    { key: 'viewProposalStructure', label: 'Proposal Structure', completed: false },
    // { key: 'viewGenerationOutline', label: 'View Outline Being Generated', completed: false },
    { key: 'viewOutline', label: 'View & Edit Outline', completed: false },
    { key: 'viewGenerationFirstDraft', label: 'View First Draft Being Generated', completed: false },
    { key: 'viewFirstDraft', label: 'Review Draft', completed: false },
  ];


  const [draftVolumes, setDraftVolumes] = useState({});
  const [tagForDraft, setTagForDraft] = useState('');
  const [volumeOutlines, setVolumeOutlines] = useState({});

  const [analysisXMLData, setAnalysisXMLData] = useState(null);
  const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);
  const [analysisError, setAnalysisError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("vm");

  const fetchData = async (keyword) => {
          setIsAnalysisLoading(true);
          setAnalysisError(null);

          const backendUrl = `http://localhost:5000/proxy?keyword=${keyword}`;

          try {
              console.log('Fetching data from backend URL:', backendUrl);
              const response = await axios.get(backendUrl);
              console.log('Response received:', response);

              const data = response.data;
              console.log('Data:', data);

              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(data, 'application/xml');
              const entries = xmlDoc.getElementsByTagName('entry');
              const extractedData = Array.from(entries).map(entry => {
                  const title = entry.getElementsByTagName('title')[0].textContent;
                  const modified = entry.getElementsByTagName('modified')[0]?.textContent;
                  const agency = entry.getElementsByTagName('ns1:agencyID')[0]?.getAttribute('name');
                  const PIID = entry.getElementsByTagName('ns1:PIID')[0]?.textContent;
                  const signedDate = entry.getElementsByTagName('ns1:signedDate')[0]?.textContent;
                  const effectiveDate = entry.getElementsByTagName('ns1:effectiveDate')[0]?.textContent;
                  const currentCompletionDate = entry.getElementsByTagName('ns1:currentCompletionDate')[0]?.textContent;
                  const ultimateCompletionDate = entry.getElementsByTagName('ns1:ultimateCompletionDate')[0]?.textContent;
                  const obligatedAmount = entry.getElementsByTagName('ns1:obligatedAmount')[0]?.textContent;
                  const baseAndExercisedOptionsValue = entry.getElementsByTagName('ns1:baseAndExercisedOptionsValue')[0]?.textContent;
                  const baseAndAllOptionsValue = entry.getElementsByTagName('ns1:baseAndAllOptionsValue')[0]?.textContent;
                  const departmentName = entry.getElementsByTagName('ns1:contractingOfficeAgencyID')[0]?.getAttribute('departmentName');
                  const descriptionOfContractRequirement = entry.getElementsByTagName('ns1:descriptionOfContractRequirement')[0]?.textContent;
                  const productOrServiceCode = entry.getElementsByTagName('ns1:productOrServiceCode')[0]?.getAttribute('description');
                  const createdBy = entry.getElementsByTagName('ns1:createdBy')[0]?.textContent;
                  const createdDate = entry.getElementsByTagName('ns1:createdDate')[0]?.textContent;
                  const lastModifiedBy = entry.getElementsByTagName('ns1:lastModifiedBy')[0]?.textContent;
                  const lastModifiedDate = entry.getElementsByTagName('ns1:lastModifiedDate')[0]?.textContent;
                  const status = entry.getElementsByTagName('ns1:status')[0]?.getAttribute('description');
                  const approvedBy = entry.getElementsByTagName('ns1:approvedBy')[0]?.textContent;
                  const approvedDate = entry.getElementsByTagName('ns1:approvedDate')[0]?.textContent;

                  return {
                      title,
                      modified,
                      agency,
                      PIID,
                      signedDate,
                      effectiveDate,
                      currentCompletionDate,
                      ultimateCompletionDate,
                      obligatedAmount,
                      baseAndExercisedOptionsValue,
                      baseAndAllOptionsValue,
                      departmentName,
                      descriptionOfContractRequirement,
                      productOrServiceCode,
                      createdBy,
                      createdDate,
                      lastModifiedBy,
                      lastModifiedDate,
                      status,
                      approvedBy,
                      approvedDate
                  };
              });

              setAnalysisXMLData(extractedData);
          } catch (error) {
              console.error('Error fetching data:', error);
              setAnalysisError(error);
          } finally {
              setIsAnalysisLoading(false);
          }
      };
      useEffect(() => {
              if (selectedTab === 'viewAnalysis') {
                  fetchData(searchKeyword);
              }
          }, [selectedTab]);

      const handleSearch = () => {
              fetchData(searchKeyword);
          };


      useEffect(() => {
              if (analysisXMLData) {
                  hljs.highlightAll();
              }
          }, [analysisXMLData]);

  const handleNavigation = (type) => {
    if (type === 'SBIR') {
      navigate('/dashboard-grants');
    } else if (type === 'RFP') {
      navigate('/dashboard');
    }
  };


  const [samOpportunities, setSamOpportunities] = useState([]);
  const [pipelineOpportunities, setPipelineOpportunities] = useState([]);
  const sampleData = {
      "totalRecords": 1104,
      "limit": 3,
      "offset": 0,
      "opportunitiesData": [
          {
              "noticeId": "fff3017484454f7eafd01e91d20d30db",
              "title": "ACTUATOR,ELECTRO-ME",
              "solicitationNumber": "N0010424QLE23",
              "fullParentPathName": "DEPT OF DEFENSE.DEPT OF THE NAVY.NAVSUP.NAVSUP WEAPON SYSTEMS SUPPORT.NAVSUP WSS MECHANICSBURG.NAVSUP WEAPON SYSTEMS SUPPORT MECH",
              "postedDate": "2024-07-11",
              "type": "Solicitation",
              "archiveType": "auto15",
              "archiveDate": "2024-08-27",
              "responseDeadLine": "2024-08-12T16:30:00-04:00",
              "naicsCode": "332919",
              "classificationCode": "4810",
              "active": "Yes",
              "pointOfContact": [
                  {
                      "fax": null,
                      "type": "primary",
                      "email": "MARSHA.WILLIAMS@NAVY.MIL",
                      "phone": null,
                      "title": null,
                      "fullName": "Telephone: 7176052727"
                  }
              ],
              "description": "https://api.sam.gov/prod/opportunities/v1/noticedesc?noticeid=fff3017484454f7eafd01e91d20d30db",
              "organizationType": "OFFICE",
              "officeAddress": {
                  "zipcode": "17050-0788",
                  "city": "MECHANICSBURG",
                  "countryCode": "USA",
                  "state": "PA"
              },
              "uiLink": "https://sam.gov/opp/fff3017484454f7eafd01e91d20d30db/view"
          },
          {
              "noticeId": "ffebd8530538472fa7fa5a1b397eb21b",
              "title": "Bison prairie seed spreading",
              "solicitationNumber": "12444524Q0076",
              "fullParentPathName": "AGRICULTURE, DEPARTMENT OF.FOREST SERVICE.USDA-FS, CSA EAST 6",
              "postedDate": "2024-07-11",
              "type": "Solicitation",
              "archiveType": "auto15",
              "archiveDate": "2024-08-19",
              "responseDeadLine": "2024-08-04T23:00:00-04:00",
              "naicsCode": "115112",
              "classificationCode": "F007",
              "active": "Yes",
              "pointOfContact": [
                  {
                      "fax": "",
                      "type": "primary",
                      "email": "Joshua.Franks@usda.gov",
                      "phone": "",
                      "title": null,
                      "fullName": "Joshua Franks"
                  }
              ],
              "description": "https://api.sam.gov/prod/opportunities/v1/noticedesc?noticeid=ffebd8530538472fa7fa5a1b397eb21b",
              "organizationType": "OFFICE",
              "officeAddress": {
                  "zipcode": "303092449",
                  "city": "Atlanta",
                  "countryCode": "USA",
                  "state": "GA"
              },
              "placeOfPerformance": {
                  "city": {
                      "code": "82088",
                      "name": "Wilmington"
                  },
                  "state": {
                      "code": "IL",
                      "name": "Illinois"
                  },
                  "zip": "60481",
                  "country": {
                      "code": "USA",
                      "name": "UNITED STATES"
                  }
              },
              "uiLink": "https://sam.gov/opp/ffebd8530538472fa7fa5a1b397eb21b/view"
          },
          {
              "noticeId": "ff76a84d2e084de5ad98ad4bfcae317a",
              "title": "S205--Medical Waste",
              "solicitationNumber": "36C24624Q0955",
              "fullParentPathName": "VETERANS AFFAIRS, DEPARTMENT OF.VETERANS AFFAIRS, DEPARTMENT OF.246-NETWORK CONTRACTING OFFICE 6 (36C246)",
              "postedDate": "2024-07-11",
              "type": "Award Notice",
              "archiveType": "autocustom",
              "archiveDate": "2024-08-10",
              "responseDeadLine": null,
              "naicsCode": "562111",
              "classificationCode": "S205",
              "active": "Yes",
              "award": {
                  "date": "2024-07-10",
                  "number": "36C24624D0062",
                  "amount": "997877.80",
                  "awardee": {
                      "name": "NEIE Medical Waste Services, LLC",
                      "location": {
                          "streetAddress": "",
                          "streetAddress2": "",
                          "city": {
                              "name": "BLAIRSVILLE"
                          },
                          "state": {
                              "name": "PA"
                          },
                          "zip": "15717",
                          "country": {
                              "name": "PA"
                          }
                      },
                      "ueiSAM": "XKA7FM3CLCX4",
                      "cageCode": "4WE82"
                  }
              },
              "pointOfContact": [
                  {
                      "fax": "",
                      "type": "primary",
                      "email": "byron.brown4@va.gov",
                      "phone": "757-315-2598",
                      "title": "Contracting Officer",
                      "fullName": "Byron L Brown"
                  }
              ],
              "description": "https://api.sam.gov/prod/opportunities/v1/noticedesc?noticeid=ff76a84d2e084de5ad98ad4bfcae317a",
              "organizationType": "OFFICE",
              "officeAddress": {
                  "zipcode": "23667",
                  "city": "HAMPTON",
                  "countryCode": "USA",
                  "state": "VA"
              },
              "uiLink": "https://sam.gov/opp/ff76a84d2e084de5ad98ad4bfcae317a/view"
          }
      ]
  };



  // Function to update state with new draft content
  const updateDraftVolume = (volumeNumber, markdownContent) => {
    ////console.log(`Updating draft volume ${volumeNumber} with new content. Content length: ${markdownContent.length}`);

    // Log the state before the update for comparison
    ////console.log('Current draftVolumes state before update:', draftVolumes);

    setDraftVolumes(prevVolumes => {
      // Log the previous state inside the update function
      ////console.log('Previous state inside update function:', prevVolumes);

      // Calculate the new state but log it before actually setting it
      const updatedVolumes = {
        ...prevVolumes,
        [volumeNumber]: markdownContent,
      };

      ////console.log(`New draftVolumes state for volume ${volumeNumber}:`, updatedVolumes);

      // Return the calculated new state
      return updatedVolumes;
    });

    // Note: Logging right after setDraftVolumes may not reflect the update immediately due to the asynchronous nature of state updates
  };
  const updateDraftVolumeOutline = (volumeIdentifier, markdownContent) => {
    ////console.log(`Updating draft volume outline ${volumeIdentifier} with new content. Content length: ${markdownContent.length}`);


      setVolumeOutlines(prevOutlines => {

          const updatedVolumes = {
            ...prevOutlines,
            [volumeIdentifier]: markdownContent
      };
      ////console.log(`New draftVolumesOutlines state for volume ${volumeIdentifier}:`, updatedVolumes);

      });

  };



  const [nextButtonActive, setNextButtonActive] = useState(false);
  const currentStep = steps.indexOf(selectedTab) + 1;

  const [streamedContent, setStreamedContent] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [numProposals, setNumProposals] = useState(0);
  const [numProposalsRFx, setNumProposalsRFx] = useState(0);
  const [preferredName, setPreferredName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [pricingTier, setPricingTier] = useState('');
  const [userID, setUserID] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [numReferrals, setNumReferrals] = useState(0);

  const [complianceMatrixAccuracy, setComplianceMatrixAccuracy] = useState(0); // Example initial value
  const [complianceMatrixSummary, setComplianceMatrixSummary] = useState(""); // Example initial value


  const [lastUpload, setLastUpload] = useState(0); // Initial value can be 0 or Date.now() or any other
  const [lastRemove, setLastRemove] = useState(0);

  const [complianceMatrixStreamed, setComplianceMatrixStreamed] = useState('');
  const [complianceMatrixStreaming, setComplianceMatrixStreaming] = useState(false);
  const [complianceMatrix, setComplianceMatrix] = useState('');
  const [complianceMatrixCreated, setComplianceMatrixCreated] = useState(false);

  const [proposalStructureStreamed, setProposalStructureStreamed] = useState('');
  const [proposalStructureStreaming, setProposalStructureStreaming] = useState(false);
  const [proposalStructure, setProposalStructure] = useState('');
  const [proposalVolumes, setProposalVolumes] = useState([]);
  //const [proposalVolumes, setProposalVolumes] = useState([
  //                                                  {
  //                                                    title: "Volume I: Technical Proposal",
  //                                                    sections: [
  //                                                      "1. **Letter of Transmittal** - Compliance with sections 2.1.1, 2.1.1 (a), 2.1.1 (b), 2.1.1 (c), 2.1.1 (d), 2.1.1 (e), 2.1.1 (f)\n\n- **1.1 Authority to Bind Statement** - Compliance with section 2.1.1 (a)\n\n- **1.2 Contact Information** - Compliance with section 2.1.1 (b)",
  //                                                      "2. **Scope of Work**",
  //                                                      "3. **System Design** - [Compliance with sections 3.1, 3.2]",
  //                                                      "4. **Technical Approach and Methodology**",
  //                                                      "5. **Project Management Plan**",
  //                                                      "6. **Testing and Quality Assurance**",
  //                                                      "7. **Post Go-Live Support Plan**",
  //                                                      "8. **Security Protocols and Disaster Recovery Plan**",
  //                                                      "9. **Appendices**"
  //                                                    ]
  //                                                  },
  //                                                  {
  //                                                    title: "Volume II: Management Proposal",
  //                                                    sections: [
  //                                                      "1. **Introduction** - [Compliance with sections 10.1, 10.2]",
  //                                                      "2. **Organizational Structure** - [Compliance with section 11.1]",
  //                                                      "3. **Management Approach**",
  //                                                      "4. **Contract Management Plan**",
  //                                                      "5. **Quality Management Plan** - [Compliance with sections 14.1, 14.2]",
  //                                                      "6. **Transition Plan** - [Compliance with sections 15.1, 15.2]",
  //                                                      "7. **Appendices**"
  //                                                    ]
  //                                                  }
  //                                                ]);
  const [proposalStructureGenerated, setProposalStructureGenerated] = useState(false);
  const [editableVolumeIndex, setEditableVolumeIndex] = useState(null);
  const [outlineTitle, setOutlineTitle] = useState('');

  const toggleEditability = (volumeIndex, volumeTag) => {
    // Join the sections array into a single string, separating each section by two newlines for clear separation
    const selectedVolumeTitle = proposalVolumes[volumeIndex].title;
    setOutlineTitle(selectedVolumeTitle);
    ////console.log("vl tag herehehre: ", volumeTag);
    setTagForDraft(volumeTag);
    const selectedVolumeContent = proposalVolumes[volumeIndex].sections.join('\n\n');
    setOutlineContent(selectedVolumeContent); // Update the outline content with the selected volume's content

    setOutlineHeadersGenerated(true); // Assuming this flag is necessary for your application logic

    const volumeKey = toRoman(volumeIndex + 1); // Adding 1 because Roman numerals start from I, not 0
    ////console.log(`View & Edit Draft clicked for volume index: ${volumeIndex}, Roman numeral: ${volumeKey}`);

    // Assuming updatedVolumes is an object with Roman numeral keys
    const selectedVolumeContent2 = draftVolumes[volumeKey];

    if (selectedVolumeContent2) {
      setOutlineFirstDraftMarkdown(selectedVolumeContent2);
      setFirstDraftGenerated(true);
      const selectedVolumeTitle = proposalVolumes[volumeIndex].title;
      setOutlineTitle(selectedVolumeTitle);
      //setOutlineContent(volume)
      setStreamedContent(selectedVolumeContent2);
    } else {
      ////console.log(`No updated content found for volume index: ${volumeIndex}, Roman numeral: ${volumeKey}`);
    }


    setSelectedTab("viewOutline"); // Switch to the "viewOutline" tab
  };

  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();
  
  const handleSelection = (selection) => {
      setIsModalOpen(false);
      if (selection === 'GRANT') {
        navigate('/dashboard-grants');
      } else if (selection === 'RFx') {
        navigate('/dashboard');
      }
    };
  
  const handleModalClose = () => {
      setIsModalOpen(false);
    };
  





  // Function to toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleTextSelection = (text) => {
    setSelectedText(text);
  };
  useEffect(() => {
    if (complianceMatrixStreaming) {
      // Perform actions that should happen after complianceMatrixStreaming is set to true
      //console.log("compl matrix streaming!");
      // Reset the streaming state if needed after the operation
      // setComplianceMatrixStreaming(false); // Uncomment if needed
    }
    // Make sure to include other dependencies if they're used inside this effect
  }, [complianceMatrixStreaming]); // Reacts whenever complianceMatrixStreaming changes
  useEffect(() => {
    if (proposalStructureStreaming) {
      // Perform actions that should happen after complianceMatrixStreaming is set to true
      //console.log("prop struc streaming!");
      // Reset the streaming state if needed after the operation
      // setComplianceMatrixStreaming(false); // Uncomment if needed
    }
    // Make sure to include other dependencies if they're used inside this effect
  }, [proposalStructureStreaming]); // Reacts whenever complianceMatrixStreaming changes
  useEffect(() => {
    if (firstDraftStreaming) {
      // Perform actions that should happen after complianceMatrixStreaming is set to true
      //console.log("first draft streaming!");
      // Reset the streaming state if needed after the operation
      // setComplianceMatrixStreaming(false); // Uncomment if needed
    }
    // Make sure to include other dependencies if they're used inside this effect
  }, [firstDraftStreaming]); // Reacts whenever complianceMatrixStreaming changes


  const streamedContentRef = useRef(null);
  useEffect(() => {
    if (streamedContentRef.current) {
      streamedContentRef.current.scrollTop = streamedContentRef.current.scrollHeight;
    }
  }, [complianceMatrixStreamed, streamedContent, outlineContent, proposalStructureStreamed]); // Dependency array ensures this runs on complianceMatrixStreamed updates

  //useEffect(() => {
  //    if (selectedTab === 'nameProposal') {
  //        console.log('Fetching SAM opportunities...');
  //        axios.get(`${BASE_URL}/govex/sam_opportunities`)
  //            .then(response => {
  //                console.log('SAM opportunities fetched successfully:', response.data);
  //                setSamOpportunities(response.data.opportunities);
  //            })
  //            .catch(error => {
  //                console.error('Error fetching SAM opportunities:', error);
  //            });
  //    }
  //}, [selectedTab]);

  useEffect(() => {
      if (selectedTab === 'samOpportunities') {
          console.log('Setting SAM opportunities with sample data...');
          setSamOpportunities(sampleData.opportunitiesData);
      }
  }, [selectedTab]);

  useEffect(() => {
      if (selectedTab === 'viewPipeline') {
          axios.get(`${BASE_URL}/get_pipeline`, {
              headers: {
                  'X-API-KEY': apiKey
              }
          })
          .then(response => setPipelineOpportunities(response.data))
          .catch(error => console.error('Error fetching pipeline opportunities:', error));
      }
  }, [selectedTab]);

  const handleAddToPipeline = (opportunity) => {
      const apiKey = kc.tokenParsed.api_key;  // Ensure your tokenParsed object has the api_key

      axios.post(`${BASE_URL}/add_to_pipeline`, {
          customer_id: kc.tokenParsed.sub,
          customer_name: kc.tokenParsed.name,
          customer_email: kc.tokenParsed.email,
          opportunity: opportunity
      }, {
          headers: {
              'X-API-KEY': apiKey
          }
      })
      .then(response => {
          console.log(response.data.message);
      })
      .catch(error => {
          console.error('Error adding to pipeline:', error);
      });
  };

  const handleRemoveFromPipeline = (solicitationNumber) => {
      console.log(`Requesting removal of solicitation_number: ${solicitationNumber}`);
      axios.post(`${BASE_URL}/remove_from_pipeline`, {
          solicitation_number: solicitationNumber
      }, {
          headers: {
              'X-API-KEY': apiKey
          }
      })
      .then(response => {
          console.log(response.data.message);
          setPipelineOpportunities(prevOpportunities =>
              prevOpportunities.filter(opportunity => opportunity.solicitationNumber !== solicitationNumber)
          );
      })
      .catch(error => console.error('Error removing from pipeline:', error));
  };






  const initialConfig = {
    editorState: prepopulateEditor,
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ]
  };
  function prepopulateEditor() {
    const root = $getRoot();
    if (root.getFirstChild() === null) {
      const heading = $createHeadingNode('h1');
      heading.append($createTextNode('Outline Headers'));
      root.append(heading);
      const paragraph = $createParagraphNode();
      paragraph.append($createTextNode('Your Outline Headers will go here for editing.'));
      root.append(paragraph);

    }
  }

  const draftConfig = {
      editorState: prepopulateEditorForDraft,
      // The editor theme
      theme: ExampleTheme,
      // Handling of errors during update
      onError(error) {
        throw error;
      },
      // Any custom nodes go here
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode
      ]
    };
  function prepopulateEditorForDraft() {
      const root = $getRoot();
      if (root.getFirstChild() === null) {
        const heading = $createHeadingNode('h1');
        heading.append($createTextNode('First Draft'));
        root.append(heading);
        const paragraph = $createParagraphNode();
        paragraph.append($createTextNode('Your First Draft will go here for editing.'));
        root.append(paragraph);
      }
    }
    useEffect(() => {
        ////console.log("loadeD + " + window.location.origin);
        kc.init({
          onLoad: initOptions.onLoad,
          KeycloakResponseType: 'code',
          silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html", checkLoginIframe: false,
          pkceMethod: 'S256'
        }).then((auth) => {
          if (!auth) {
            ////console.log("loadeD1");
            kc.login();
          } else {
            setIsAuthenticated(true);
            setIsPageLoading(false);
            const decodedToken = jwtDecode(kc.token);
            //console.log('Decoded Token:', decodedToken);

            setKeyCloakToken(kc.token);

            const companyNameFromToken = decodedToken.company_name;
            setCompanyName(companyNameFromToken);

            const numProposalsFromToken = decodedToken.num_proposals;
            setNumProposals(numProposalsFromToken);

            const numProposalsRFxFromToken = decodedToken.num_proposals_rfx;
            setNumProposalsRFx(numProposalsRFxFromToken);

            const preferredNameFromToken = decodedToken.name;
            setPreferredName(preferredNameFromToken);

            const userEmailFromToken = decodedToken.email;
            setUserEmail(userEmailFromToken);

            const apiKeyFromToken = decodedToken.api_key;
            setApiKey(apiKeyFromToken);

            const pricingPlanFromToken = decodedToken.pricing_plan;
            setPricingTier(pricingPlanFromToken);

            const userIDFromToken = decodedToken.sub;
            setUserID(userIDFromToken);

            const referralCodeFromToken = decodedToken.referral_code;
            setReferralCode(referralCodeFromToken);
            //console.log(decodedToken);

            const numReferralsFromToken = decodedToken.num_referrals;
            setNumReferrals(numReferralsFromToken);

            const fetchGroupId = async () => {
              if (!userIDFromToken) {
                console.log("User ID is not available");
                return; // Exit if the userIDFromToken is not set
              }
              //console.log("Fetching Group ID for user:", userIDFromToken);

              try {
                // Adjust the request as needed to match your API endpoint and parameters
                const response = await fetch(`${BASE_URL}/govex/get-user-group?user_id=${userIDFromToken}`, {
                  method: 'GET', // or 'POST' if required by your endpoint
                  headers: {
                    'Authorization': `Bearer ${kc.token}`, // Ensure the token is attached if needed
                    'Content-Type': 'application/json'
                  },
                });

                if (!response.ok) {
                  throw new Error(`Failed to fetch group ID, status: ${response.status}`);
                }

                const text = await response.text(); // First get the response as text

                try {
                  const data = JSON.parse(text); // Then attempt to parse it as JSON
                  setGroupId(data.groupId); // Assuming your API returns a JSON object with a groupId property
                  //console.log('Group ID:', data.groupId);
                } catch (error) {
                  // This block catches any errors in the JSON parsing process
                  console.error('Error parsing JSON:', error);
                  console.error('Received:', text); // Logs the actual response text
                }
              } catch (error) {
                // This block catches network errors and errors thrown from within the try block
                console.error('Error fetching group ID:', error);
              }
            };




            ////console.log('Company Name:', companyNameFromToken);
            ////console.log('Num Proposals:', numProposalsFromToken);
            ////console.log('Name:', preferredNameFromToken);
            ////console.log('Email:', userEmailFromToken);
            ////console.log('API Key:', apiKeyFromToken);
            ////console.log('Pricing Tier:', pricingPlanFromToken);
            ////console.log('User ID:', userIDFromToken);

            ////console.info("Authenticated");
            ////console.log('auth', auth)
            ////console.log('Keycloak', kc)
            fetchGroupId();
            kc.onTokenExpired = () => {
              ////console.log('token expired')
            }
          }
        }, () => {
          //console.error("Authenticated Failed");
        });
      }, []);










  useEffect(() => {
      if (selectedTab === 'viewComplianceMatrix') {
          const timer = setTimeout(() => {
              const banner = document.getElementById('banner');
              if (banner) {
                  banner.style.display = 'none';
              }
          }, 10000); // Hide after 5000 milliseconds

          // Cleanup function to clear the timer if the component unmounts
          return () => clearTimeout(timer);
      }
  }, [selectedTab]);


  const handleOutlineHeadersChange = (e) => {
    setEditedOutlineHeaders(e.target.value);
    ////console.log("edited outline headers: " + editedOutlineHeaders);
  };

  useEffect(() => {
    ////console.log(`Selected tab is now: ${selectedTab}`);

    switch (selectedTab) {
      case 'nameProposal':
        // Logic for when the 'Select Workflow' tab is selected
        ////console.log("Handling 'Select Workflow'");
        if (proposalProcessName)
            setNextButtonActive(true);
        break;
      case 'manageDatabase':
        // Logic for when the 'Manage Database' tab is selected
        ////console.log("Handling 'Manage Database'");
        if (proposalProcessName)
            setNextButtonActive(true);
        break;
      case 'generateProposal':
        // Logic for when the 'Upload RFP/RFI/RFQ/SBIR' tab is selected
        ////console.log("Handling 'Upload RFP/RFI/RFQ/SBIR'");
        if (complianceMatrixCreated)
            setNextButtonActive(true);
        else
            setNextButtonActive(false);
        break;
      case 'viewComplianceMatrix':
        // Logic for when the 'Compliance Matrix' tab is selected
        ////console.log("Handling 'Compliance Matrix'");
        if (complianceMatrixCreated && proposalStructureGenerated)
            setNextButtonActive(true);
        break;
      case 'viewProposalStructure':
        // Logic for when the 'Proposal Structure' tab is selected
        ////console.log("Handling 'Proposal Structure'");
        if (proposalStructureGenerated && outlineContent)
            setNextButtonActive(true);
        break;
      case 'viewOutline':
        // Logic for when the 'View & Edit Outline' tab is selected
        ////console.log("Handling 'View & Edit Outline'");
        if (firstDraftGenerated)
            setNextButtonActive(true);
        break;
      case 'viewGenerationFirstDraft':
        // Logic for when the 'View First Draft Being Generated' tab is selected
        ////console.log("Handling 'View First Draft Being Generated'");
        break;
      case 'viewFirstDraft':
        // Logic for when the 'Review Draft' tab is selected
        ////console.log("Handling 'Review Draft'");
        break;
      default:
        // Logic for when no specific tab is selected or an unrecognized tab is selected
        ////console.log('No specific logic for this tab');
    }

  }, [selectedTab]);







  useEffect(() => {
    // This log will now reflect the updated companyName when the useEffect runs
    // Only fetch products if companyName is not empty
    if (userID) {
      const fetchProducts = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/list-products`, {
            headers: {
              'X-Company-Name': companyName,
              'X-User-ID': userID,
            },
          });
          setProducts(response.data);
        } catch (error) {
          //console.error('Error fetching products:', error);
        }
      };

      fetchProducts();
    }
  }, [userID, lastUpload, lastRemove]); // Add companyName as a dependency here

  useEffect(() => {
      // This log will now reflect the updated companyName when the useEffect runs
      ////console.log("company name: " + companyName);

      // Only fetch products if companyName is not empty
      if (userID) {
        const fetchProducts = async () => {
          try {
            const response = await axios.get(`${BASE_URL}/list-proposal-processes`, {
              headers: {
                'X-Company-Name': companyName,
                'X-User-ID': userID,
              },
            });
            const updatedProposalProcesses = {};
            for (const [productName, files] of Object.entries(response.data)) {
              updatedProposalProcesses[productName] = files.map(file => ({
                name: file.file_name, // Adjust according to your state structure
                downloadUrl: file.download_url, // Adjust according to your state structure
              }));
            }
            setProposalProcesses(updatedProposalProcesses);
          } catch (error) {
            //console.error('Error fetching products:', error);
          }
        };

        fetchProducts();
      }
    }, [userID, lastUpload, lastRemove]); // Add companyName as a dependency here

  const handleDownloadFromAllProductsDiv = (file) => {
    // Assuming 'file' is an object with { name, downloadUrl }
    const { downloadUrl, name } = file;

    if (!downloadUrl) {
      //console.error('Download URL not found for file:', name);
      return;
    }

    // Create an anchor element and trigger the download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', name); // This will prompt a file download with the original file name
    document.body.appendChild(link);
    link.click();
    link.remove(); // Clean up
  };





  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*', // specify file types
    onDrop: (acceptedFiles) => {
      handleProductFilesChange(acceptedFiles);
    },
  });




  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      // Immediately clear the input after setting the file
      event.target.value = null;
    }
  };


  const handlePreviousFileChange = (event) => {
    setSelectedPreviousFile(event.target.files[0]);
  };
  const handleFileUploadMatrix = async () => {
    const formData = new FormData();
    formData.append('productFile', selectedFile);
    ////console.log("selected file - ", selectedFile);

    // Include any additional data as needed
    formData.append('tag', 'rfp');
    formData.append('userID', userID);
    formData.append('proposalName', proposalProcessName)
    formData.append('file', selectedFile);


    // Include the previous proposal file if selected
    //if (selectedPreviousFile) {
    //  formData.append('previousFile', selectedPreviousFile);
    //}

    setIsProcessing(true);


    try {

        const saveResponse = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${keycloakToken}`,
            },
            body: formData,
        });

        if (!saveResponse.ok) {
            throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        //console.log("File saved with 'rfp' tag, continuing with outline header processing.");
        await new Promise(resolve => setTimeout(resolve, 2000));

    } catch (error) {
      //console.error('Error uploading files:', error);
    }

    setIsProcessing(false);
  };


  const handleFileUpload = async () => {

    const formData = new FormData();
    formData.append('productFile', selectedFile);
    ////console.log("selected file - ", selectedFile);

    // Include any additional data as needed
    formData.append('tag', 'rfp');
    formData.append('userID', userID);
    formData.append('proposalName', proposalProcessName)
    formData.append('file', selectedFile);


    // Include the previous proposal file if selected
    //if (selectedPreviousFile) {
    //  formData.append('previousFile', selectedPreviousFile);
    //}

    setIsProcessing(true);


    try {

        const saveResponse = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${keycloakToken}`,
            },
            body: formData,
        });

        if (!saveResponse.ok) {
            throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        //console.log("File saved with 'rfp' tag, continuing with outline header processing.");
        await new Promise(resolve => setTimeout(resolve, 2000));



        ////console.log(`Sending request to ${BASE_URL}/govex/process-pdf-create-outline-headers`);
        const response = await fetch(`${BASE_URL}/govex/process-pdf-create-outline-headers`, {
            method: 'POST',
            body: formData,
        });

        ////console.log(`Response status: ${response.status}, Status text: ${response.statusText}`);
        if (!response.ok) {
            throw new Error(`Stream response was not ok: ${response.statusText}`);
        }

        ////console.log("Stream response received, starting to process");
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        ////console.log("Decoder and reader initialized");

        setSelectedTab("viewGenerationOutline");
        ////console.log("Selected tab set to viewGenerationOutline");

        let completeOutline = '';


        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                ////console.log("Streaming complete");
                break;
            }
            const chunk = decoder.decode(value, { stream: true });
            ////console.log("Chunk received:", chunk);
            completeOutline += chunk;
            setOutlineContent((prevContent) => prevContent + chunk);
            setEditedOutlineHeaders((prevContent) => prevContent + chunk);
        }


        //// Now, send the complete proposal content to finalize the proposal
        //const finalizeOutline = await fetch('http://localhost:5000/govex/finalize-outline', {
        //    method: 'POST',
        //    body: JSON.stringify({ finalizedOutline: completeOutline }),
        //});
        //
        //if (!finalizeOutline.ok) {
        //    throw new Error(`Finalize response was not ok: ${finalizeOutline.statusText}`);
        //}
        //
        //const finalizeData = await finalizeOutline.json();
        ////console.log("Finalize response received:", finalizeData);
        //
        //setOutlineContent(finalizeData.finalizedOutline);


        setShowEditor(true);
        setOutlineHeadersGenerated(true);

        setSelectedTab("viewOutline");


        //setOutlineContent("");
        //setEditedOutlineHeaders("");
        //setShowEditor(true);
        //setOutlineHeadersGenerated(true);

    } catch (error) {
      //console.error('Error uploading files:', error);
    }

    setIsProcessing(false);
  };
  const userInfo = {
    companyName: companyName,
    username: preferredName,
    email: userEmail,
    numProposals: numProposals,
    numProposalsRFx: numProposalsRFx,
    apiKey: apiKey,
    pricingTier: pricingTier
  };


  const handleLogout = () => {
    kc.logout({ redirectUri: 'https://govexai.com/' });
  };

  const handleProposalProcessNameChange = (event) => {
    // Prevent slashes in the proposal process name
    const updatedName = event.target.value.replace(/\//g, '');
    setProposalProcessNameInText(updatedName);
  };


  const handleProposalProcessSubmit = async (e) => {

      e.preventDefault();
      setProposalProcessName(proposalProcessNameInText);
      setNextButtonActive(true);
      const formData = new FormData();
      formData.append('proposalName', proposalProcessName);

      try {
          const token = kc.token; // Assuming 'kc' is correctly initialized
          ////console.log("token: " + token);

          const response = await axios.post(`${BASE_URL}/govex/create-folder`, formData, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });

          ////console.log(response.data);
          setSelectedFile(null);
          setFirstDraftGenerated(false);
          setOutlineFirstDraftMarkdown('');
          setOutlineHeadersGenerated(false);
          setOutlineContent('');
          setSelectedTab("manageDatabase");
      } catch (error) {
          //console.error('Error creating folder:', error);
      }
  };

  const handleProposalProcessContinueWork = async (productName) => {
      // Check if the product is an SBIR workflow
      const isSBIRWorkflow = proposalProcesses[productName].some(file => file.name.endsWith('.json'));

      if (isSBIRWorkflow) {
          // Redirect to /dashboard-grants
          window.location.href = '/dashboard-grants';

          // Delay execution to ensure the redirection takes place
          setTimeout(() => continueSBIRWork(productName), 1000);
      } else {
          // Continue with the rest of the logic if it's not an SBIR workflow
          continueSBIRWork(productName);
      }
  };

  const continueSBIRWork = async (productName) => {
    setProposalProcessName(productName); // Assuming this sets the context for further operations
    setNextButtonActive(true);


    try {

      // Fetch the file information for the given productName with the 'rfp' tag
      const formData = new FormData();
      ////console.log("uuu id", userID);

      // Include any additional data as needed
      formData.append('tags', 'rfp,draft,outline,matrix,proposal-structure');
      formData.append('userID', userID);
      formData.append('proposalName', productName);
      formData.append('groupID', groupId);
      formData.append('companyName', companyName);

      const fileInfoResponse = await fetch(`${BASE_URL}/govex/fetch-file-info`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${keycloakToken}`,
        },
        body: formData,
      });

      if (fileInfoResponse.status === 200) {
          const files = await fileInfoResponse.json();  // 'files' is expected to be an array of file information objects
          ////console.log("Files info:", files);

          // Iterate over each file
          files.forEach(file => {
              // Check if the tag is 'rfp'
              ////console.log("Inspecting file:", file);

              // Check if the file has a tag that matches the pattern 'draft_volume_\d+'
              const draftVolumeTag = file.tags.find(tag => /^draft_volume_(I|II|III)$/.test(tag));
              const draftVolumeOutlineTag = file.tags.find(tag => /^draft_volume_(\w+)_outline$/.test(tag));

              if (file.tags && file.tags.includes('rfp')) {
                  // Fetch the RFP file and set it as selectedFile
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          return response.blob();
                      })
                      .then(blob => {
                          const filename = file.filename || 'defaultFilename.pdf';
                          ////console.log("RFP Filename - ", filename);
                          const rfpFile = new File([blob], filename, { type: 'application/pdf' });
                          setSelectedFile(rfpFile);  // Assuming 'setSelectedFile' updates the state with the fetched RFP file
                          setSelectedTab("manageDatabase");
                      })
                      .catch(error => {
                          //console.error('Fetch error for RFP file:', error.message);
                          setSelectedTab("manageDatabase");
                      });
              } else if (draftVolumeOutlineTag) {
                  ////console.log(`Found draft volume outline tag (${draftVolumeOutlineTag}) in file:`, file.filename);
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          return response.blob();
                      })
                      .then(blob => {
                          const formData = new FormData();
                          formData.append("file", blob, file.filename);

                          return fetch(`${BASE_URL}/govex/convert-docx-to-markdown`, {
                              method: 'POST',
                              body: formData,
                          });
                      })
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Conversion API response was not ok: ${response.statusText}`);
                          }
                          return response.json(); // Assuming the API returns the Markdown content in JSON format
                      })
                      .then(data => {
                          const markdownContent = data.markdown;

                          // Extract the volume identifier from the draftVolumeOutlineTag
                          const volumeIdentifier = draftVolumeOutlineTag.match(/^draft_volume_(\w+)_outline$/)[1];
                          ////console.log(`Processing outline for volume ${volumeIdentifier}`);

                          // Assuming you have a method to update a specific volume's outline content
                          // This method should update the state holding the outlines, keyed by volume identifier
                          updateDraftVolumeOutline(volumeIdentifier, markdownContent);
                      })
                      .catch(error => {
                          //console.error('Error processing draft volume outline:', error.message);
                      });
              }

              else if (file.tags && file.tags.includes('proposal-structure')) {
                  ////console.log("Starting file fetch:", file.downloadUrl);
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          //console.log("File fetch successful");
                          return response.text(); // Change here to get text instead of blob, as Markdown is plain text
                      })
                      .then(markdownContent => {
                          ////console.log("Received Markdown content:", markdownContent);

                          // Since you already have the Markdown content, you can directly proceed to use it
                          //console.log("Finalizing proposal structure");
                          return fetch(`${BASE_URL}/govex/finalize-proposal-structure`, {
                              method: 'POST',
                              headers: {
                                  'Content-Type': 'application/json',
                                  // Include authorization headers as needed, e.g.:
                                  'Authorization': `Bearer ${keycloakToken}`,
                              },
                              body: JSON.stringify({
                                  finalizedProposalStructure: markdownContent // Use the Markdown content directly
                              }),
                          });
                      })
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Error finalizing proposal structure: ${response.statusText}`);
                          }
                          //console.log("Proposal structure finalization response received");
                          return response.json();
                      })
                      .then(data => {
                          ////console.log("Finalized proposal structure received:", data.finalizedProposalStructure);
                          setProposalVolumes(data.finalizedProposalStructure);  // Update your state with the structured volumes
                          setProposalStructureGenerated(true);
                          setNextButtonActive(true);
                          setProposalStructureStreaming(true);
                      })
                      .catch(error => {
                          //console.error('Error in the process:', error);
                      });
              }


              else if (file.tags && file.tags.includes('matrix')) {
                fetch(file.downloadUrl)
                  .then(response => response.blob())
                  .then(blob => {
                    // Convert the blob to a markdown string using the refactored function
                    return processExcelBlobToMarkdown(blob);
                  })
                  .then(markdownString => {
                    // Set the markdown string in your component's state
                    setComplianceMatrixStreaming(true);
                    setComplianceMatrix(markdownString);
                    setComplianceMatrixStreamed(markdownString);
                    setComplianceMatrixCreated(true);
                  })
                  .catch(error => console.error('Error fetching matrix file:', error));
              }
              else if (draftVolumeTag) {
                ////console.log(`Processing ${draftVolumeTag}`);
                const volumeNumber = draftVolumeTag.split('_').pop(); // Extract the volume number
                ////console.log(`Extracted volume number: ${volumeNumber}`);

                fetch(file.downloadUrl)
                  .then(response => {
                    if (!response.ok) {
                      //console.error(`Network response was not ok: ${response.statusText}`);
                      return Promise.reject(`Network response was not ok: ${response.statusText}`);
                    }
                    return response.blob();
                  })
                  .then(blob => {
                    ////console.log(`Blob fetched for volume ${volumeNumber}`);
                    // Convert to FormData as before
                    const formData = new FormData();
                    formData.append("file", blob, file.filename);

                    ////console.log(`FormData prepared for volume ${volumeNumber}`);

                    // Call your conversion service
                    return fetch(`${BASE_URL}/govex/convert-docx-to-markdown`, {
                      method: 'POST',
                      body: formData,
                    });
                  })
                  .then(response => {
                    if (!response.ok) {
                      ////console.error(`Conversion API response was not ok for volume ${volumeNumber}: ${response.statusText}`);
                      return Promise.reject(`Conversion API response was not ok: ${response.statusText}`);
                    }
                    return response.json();
                  })
                  .then(data => {
                    const markdownContent = data.markdown;
                    //////console.log(`Conversion successful for volume ${volumeNumber}, content length: ${markdownContent.length}`);
                    //console.log({markdownContent});

                    //console.log("markdown content for volume: ",volumeNumber, "\n\n", markdownContent);

                    // Dynamically update the state with the new draft content
                    updateDraftVolume(volumeNumber, markdownContent);
                    ////console.log("draft volume - ", draftVolumes);
                  })
                  .catch(error => console.error(`Error processing draft volume ${volumeNumber}:`, error));
              }



          });
          setSelectedTab("manageDatabase");
      } else {
          console.error('Error fetching file information:', fileInfoResponse.statusText);
          // Handle HTTP errors, e.g., show an error message to the user
      }
    } catch (error) {
      console.error('Error fetching file information:', error);
    }
  };




  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handleProductFilesChange = (acceptedFiles) => {
    const newFiles = acceptedFiles.map(file =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setProductFiles(currentFiles => [...currentFiles, ...newFiles]);
  };


  const removeFile = (fileName) => {
    setProductFiles(currentFiles =>
      currentFiles.filter(file => file.name !== fileName)
    );
  };

  const filesList = productFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <button onClick={() => removeFile(file.name)}>Remove</button>
    </li>
  ));




  useEffect(() => {
    // Clean up the data URIs
    return () => {
      productFiles.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [productFiles]);
  useEffect(() => {
      // Delay execution within useEffect
      const timer = setTimeout(() => {
          console.log(preferredName);
          console.log(userEmail);
          document.tidioIdentify = {
              email: userEmail, // Visitor's email
              name: preferredName, // Visitor's name
          };

          // Create script element for Tidio chat
          const script = document.createElement('script');
          script.src = "//code.tidio.co/dp06620e1bdrj8bk1rycsqkepzijhq9x.js";
          script.async = true;

          // Append script to the body
          document.body.appendChild(script);

          // Remove script on component unmount
          return () => {
              document.body.removeChild(script);
          };
      }, 3000); // 3000 milliseconds = 3 seconds delay

      // Cleanup function to clear the timeout if the component unmounts before the timeout is completed
      return () => {
          clearTimeout(timer);
      };
  }, [preferredName, userEmail]); // Depend on preferredName and userEmail to trigger this effect



  const handleAddProductClick = () => {
    setIsAddingProduct(true);
  };
  const handleUploadProductToDB = async () => {
    const formData = new FormData();
    formData.append('productName', productName);
    productFiles.forEach(file => {
      formData.append('productFiles', file);
    });

    try {
      // Assuming 'keycloak' is your Keycloak instance
      const token = await kc.token; // Retrieve the Keycloak token
      ////console.log("token: " + token);

      const response = await axios.post(`${BASE_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      //console.log(response.data);
      setUploadSuccess(true); // Set success message state
      setIsAddingProduct(false); // Hide the upload form
      setLastUpload(Date.now()); // Update the timestamp or simply increment if using a counter
    } catch (error) {
      //console.error('Error uploading files:', error);
      setUploadSuccess(false); // Reset on error
    }
  };

  const handleAddAnotherProduct = () => {
    setProductName('');
    setProductFiles([]);
    setUploadSuccess(false);
    setIsAddingProduct(true);
  };

  const handleRemoveFile = async (productName, fileName) => {
    try {
      await axios.post(`${BASE_URL}/remove-file`, { productName, fileName, companyName, userID });

      // Remove the file from the state
      setProducts(prevProducts => {
        const updatedProducts = { ...prevProducts };
        updatedProducts[productName] = updatedProducts[productName].filter(file => file !== fileName);
        return updatedProducts;
      });

      setLastRemove(Date.now());

    } catch (error) {
      //console.error('Error removing file:', error);
    }
  };
  const handleRemoveProcessProposalFile = async (productName, fileName) => {
    try {
      await axios.post(`${BASE_URL}/govex/remove-file-process-proposal`, { productName, fileName, companyName, userID });

      // Remove the file from the state
      setProposalProcesses(prevProducts => {
        const updatedProcesses = { ...prevProducts };
        updatedProcesses[productName] = updatedProcesses[productName].filter(file => file !== fileName);
        return updatedProcesses;
      });

      setLastRemove(Date.now());

    } catch (error) {
      //console.error('Error removing file:', error);
    }
  };


  const handleUploadFileToDB = async (documentFile, volumeTag) => { // Add async keyword here
      const formData = new FormData();
      formData.append('productName', proposalProcessName);
      formData.append('productFiles', documentFile);
      const token = kc.token;

      try {
          const response = await axios.post(`${BASE_URL}/upload`, formData, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });

          //console.log(response.data);
      } catch (error) {
          //console.error('Error uploading files:', error);
      }

      const formData2 = new FormData();
      formData2.append('productName', proposalProcessName);
      formData2.append('productFiles', documentFile);
      formData2.append('tag', volumeTag);
      //console.log("tag!: ", volumeTag);
      try {
          const response = axios.post(`${BASE_URL}/govex/upload`, formData2, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });

          //console.log(response.data);
      } catch (error) {
          //console.error('Error uploading files:', error);
      }



  };




  const handleResponseFromGenerateFullOutline = (outlineMarkdown, newNumProposals, documentFileFromGFO, volumeTag, outlineHeaders) => {
    //console.log(documentFileFromGFO);
    setOutlineHeadersGenerated(true);
    //console.log("\n\n\n\n\n");
    //console.log("volumetag here: ", volumeTag);
    handleUploadFileToDB(documentFileFromGFO, volumeTag);
    setOutlineFirstDraftMarkdown(outlineMarkdown);
    setFirstDraftGenerated(true);
    setSelectedTab('viewFirstDraft');
    setNumProposals(newNumProposals);
    setOutlineContent(outlineHeaders);


    //console.log("completed!");
  };

  const handleResponseFromSaveDraft = async (newDraftContent, volumeTag) => {

    //console.log("\n\n\n\n\n");
    //console.log("volumetag here: ", volumeTag);


    //console.log("completed!");


  }

  const handleResponseFromGenerateComplianceMatrix = (completeComplianceMatrix, complianceMatrixAccuracyFromResponse, complianceMatrixSummaryFromResponse) => {
    //console.log("Received compliance matrix:", completeComplianceMatrix);
    setComplianceMatrix(completeComplianceMatrix);
    setComplianceMatrixCreated(true);
    setNextButtonActive(true);
    saveExcelFileToDBImport(completeComplianceMatrix);
    handleExportToExcelWithParam(completeComplianceMatrix);
    setComplianceMatrixAccuracy(complianceMatrixAccuracyFromResponse);
    setComplianceMatrixSummary(complianceMatrixSummaryFromResponse);
    //console.log("Compliance matrix set:", completeComplianceMatrix);
  }
  useEffect(() => {
    console.log("Updated proposalVolumes:", proposalVolumes);
  }, [proposalVolumes]); // Dependency array, effect runs when proposal


  useEffect(() => {
    console.log(selectedRefFiles);
  }, [selectedRefFiles]); // This tells React to call this effect only when selectedRefFiles changes.

  const handleExportToExcelWithParam = (complianceMatrixFromResponse) => {
      const markdownTable = complianceMatrixFromResponse; // Your markdown table string

      const lines = markdownTable.trim().split('\n');
      const headers = lines[0].split('|').slice(1, -1).map(header => header.trim());

      // Skip the markdown table's header and separator lines for data rows
      const dataRows = lines.slice(2).map(row => {
          const cells = row.split('|').slice(1, -1).map(cell => cell.trim());
          return headers.reduce((obj, header, i) => {
              obj[header] = cells[i];
              return obj;
          }, {});
      });

      // Initialize an empty worksheet
      const ws = XLSX.utils.json_to_sheet([]);

      // Adding the title and ensuring it spans the correct range
      XLSX.utils.sheet_add_json(ws, [{"Compliance Matrix": "A"}], {origin: "A1"});
      ws["!merges"] = [{s: {r: 0, c: 0}, e: {r: 0, c: headers.length - 1}}]; // Ensure merge spans correct columns

      // Manually setting headers in the next row (Excel row 2)
      const headerRow = XLSX.utils.encode_row(1); // Excel rows are 1-indexed
      headers.forEach((header, i) => {
          const cellRef = XLSX.utils.encode_cell({r: 1, c: i});
          ws[cellRef] = {t: 's', v: header};
      });

      // Adding data rows, starting below the headers
      XLSX.utils.sheet_add_json(ws, dataRows, {origin: "A2"});

      // Set column widths as needed
      ws['!cols'] = [{wch: 10}, {wch: 100}, {wch: 50}, {wch: 20}, {wch: 12}];
      // After adding data to the worksheet (ws)

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ComplianceMatrix");

      const excelFileName = proposalProcessName + "-ComplianceMatrix.xlsx";
      XLSX.writeFile(wb, excelFileName);
  };


  const handleResponseFromGenerateProposalStructure = (finalizedProposalStructureArray) => {
      // Assuming 'setProposalVolumes' is the state updater function for 'proposalVolumes'
      console.log(finalizedProposalStructureArray);
      setProposalVolumes(finalizedProposalStructureArray);
      console.log("Current proposalVolumes:", proposalVolumes);
      setProposalStructureGenerated(true);
      setNextButtonActive(true);
      const markdownStringProposalStructure = convertToMarkdown(finalizedProposalStructureArray);
      console.log(markdownStringProposalStructure);
      downloadAndSaveMarkdownAsDocx(markdownStringProposalStructure);
      //console.log("success");
  };


  const handleResponseFromEditOutline = async (newOutlineContent, volumeTag) => {
        //console.log("Received new Outline content:", newOutlineContent);
        //console.log("Current proposalVolumes:", proposalVolumes);
        //console.log("Current volumeTag being updated:", volumeTag);

        // Adjust the volumeTag by removing '_outline' for the lookup
        const adjustedVolumeTag = volumeTag.replace('_outline', '');
        //console.log("Adjusted volumeTag for lookup:", adjustedVolumeTag);

        const volumeIndex = proposalVolumes.findIndex(volume => volume.tag === adjustedVolumeTag);
        if (volumeIndex !== -1) {
            //console.log(`Found volume at index ${volumeIndex}, proceeding to update...`);

            //console.log(`volume ${volumeIndex} before new content:`, JSON.stringify(proposalVolumes[volumeIndex], null, 2));

            // Split the new outline content by lines and process it
            const lines = newOutlineContent.split('\n');
            let updatedSections = [];
            let currentSection = '';

            lines.forEach((line, index) => {
                // Trim the line to remove leading and trailing whitespaces
                line = line.trim();

                // Check if the line is a subsection or a section
                // Assuming subsections start with a dash (-) and sections start with a number
                if (line.startsWith('-')) {
                    // For subsections, prepend whitespace for indentation
                    line = `   ${line}`; // Adjust the number of spaces based on the desired indentation
                } else {
                    // For new sections, add the previous section to the updatedSections array
                    // and start a new currentSection string
                    if (currentSection !== '') {
                        updatedSections.push(currentSection);
                        currentSection = '';
                    }
                }

                // Append the current line to the current section, including subsections
                if (currentSection === '') {
                    currentSection = line;
                } else {
                    currentSection += `\n${line}`;
                }

                // Handle the last line to ensure it's added to the updatedSections
                if (index === lines.length - 1 && currentSection !== '') {
                    updatedSections.push(currentSection);
                }
            });

            proposalVolumes[volumeIndex].sections = updatedSections;

            //console.log(`Updated volume ${volumeIndex} with new content:`, JSON.stringify(proposalVolumes[volumeIndex], null, 2));

            // Convert updatedVolumes to a markdown format or structure that your backend expects
            const proposalStructureContent = convertToMarkdownPropStruct(proposalVolumes);

            //console.log("Proposal structure content ready to be sent to the backend:", proposalStructureContent);




            try {
                // Now, send the complete proposal content to finalize the proposal
                const finalizeResponse = await fetch(`${BASE_URL}/govex/finalize-proposal-structure`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ finalizedProposalStructure: proposalStructureContent }),
                });

                if (!finalizeResponse.ok) {
                    throw new Error(`Finalize response was not ok: ${finalizeResponse.statusText}`);
                }

                const finalizeData = await finalizeResponse.json();
                //console.log("Finalize response received:", finalizeData);

                // Assuming finalizeData contains the finalized proposal volumes structure
                setProposalVolumes(finalizeData.finalizedProposalStructure);

                // Proceed with the download and save operation using the finalized proposal structure
                const finalPropStruct = convertToMarkdown(finalizeData.finalizedProposalStructure);
                //console.log(finalPropStruct);
                downloadAndSaveMarkdownAsDocx(finalPropStruct);

            } catch (error) {
                //console.error("Error finalizing proposal structure:", error);
            }
        } else {
            //console.error("Could not find the volume to update. Check the volumeTag and proposalVolumes state.");
        }
    };

    const convertToMarkdownPropStruct = (finalizedProposalStructureArray) => {
      let markdownString = "";

      finalizedProposalStructureArray.forEach(volume => {
        // Add volume title as a heading
        markdownString += `# ${volume.title}\n\n`;

        // Add each section within the volume
        volume.sections.forEach(section => {
          markdownString += `${section}\n`;
        });

        // Add a newline for spacing between volumes
        markdownString += '\n';
      });

      return markdownString;
    };






  const convertToMarkdown = (finalizedProposalStructureArray) => {
    let markdownString = "";

    finalizedProposalStructureArray.forEach(volume => {
      // Add volume title as a heading
      markdownString += `# ${volume.title}\n\n`;

      // Add each section within the volume
      volume.sections.forEach(section => {
        markdownString += `${section}\n`;
      });

      // Add a newline for spacing between volumes
      markdownString += '\n';
    });

    return markdownString;
  };
  const downloadAndSaveMarkdownAsDocx = async (markdownString) => {
    try {
      const blob = new Blob([markdownString], { type: 'text/markdown' });
      const filename = `${proposalProcessName}-proposal-structure.md`;

      // Create a URL for the file
      const formData = new FormData();
      formData.append('productName', proposalProcessName);
      formData.append('productFile', blob, filename);
      formData.append('tag', 'proposal-structure');
      formData.append('userID', userID);
      formData.append('proposalName', proposalProcessName)

      try {
            const saveResponse = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${keycloakToken}`,
                },
                body: formData,
            });

            if (!saveResponse.ok) {
                throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
            }

            //console.log("Markdown file saved with 'proposal-structure' tag, continuing.");
        } catch (error) {
            //console.error('Error uploading files:', error);
            return; // Exit if file upload fails
        }

        // Directly download the markdown file without conversion
        //const downloadUrl = window.URL.createObjectURL(blob);
        //const link = document.createElement('a');
        //link.href = downloadUrl;
        //link.setAttribute('download', filename); // Set the downloaded file name
        //document.body.appendChild(link);
        //link.click();
        //link.remove(); // Clean up after downloading
      } catch (error) {
          //console.error('Error in processing markdown file:', error);
      }
  };








  const removeSelectedFile = () => {
    setSelectedFile(null); // Clear the selected file
  };

  if (isPageLoading) {
    return <div className="loading">Loading...</div>; // Or any other loading indicator
  }

  if (!isAuthenticated) {
    // Potentially redundant, as the user should be redirected by Keycloak if not authenticated
    return <div className="loading">Redirecting to login...</div>;
  }

  const currentStepIndex = steps.findIndex(step => step.key === selectedTab);

  const goToNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      setSelectedTab(steps[currentStepIndex + 1].key);
    }
  };
  const goToNextStepOutlineToDraft = () => {
    setSelectedTab("viewFirstDraft");
  };

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      setSelectedTab(steps[currentStepIndex - 1].key);
    }
  };
  const saveExcelFileToDB = async () => {
    //console.log("in savetodexcel: ", complianceMatrix);
    const markdownTable = complianceMatrix; // Assuming complianceMatrix contains your markdown table string
    const lines = markdownTable.trim().split('\n');
    const headers = lines[0].split('|').slice(1, -1).map(header => header.trim());
    const dataRows = lines.slice(2);

    // Parse rows into objects
    const dataObjects = dataRows.map(row => {
      const cells = row.split('|').slice(1, -1).map(cell => cell.trim());
      return cells.reduce((obj, cell, index) => {
        obj[headers[index]] = cell;
        return obj;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(dataObjects);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ComplianceMatrix");

    // Instead of writing to file, write to binary string and convert to Blob
    const wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});
    let buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
    let view = new Uint8Array(buf);  //create uint8array as viewer
    for (let i=0; i<wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF; //convert to octet

    // Create Blob from ArrayBuffer
    const blob = new Blob([buf], {type:'application/octet-stream'});


    const excelFileName = `${proposalProcessName}-ComplianceMatrix.xlsx`;
    const file = new File([blob], excelFileName, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

    // Store the File object in the state
    setExcelFileSelected(file);
    const formData = new FormData();
    formData.append('productFile', file);
    formData.append('productName', proposalProcessName);
    //console.log("selected file - ", file);

    // Include any additional data as needed
    formData.append('tag', 'matrix');
    formData.append('userID', userID);
    //console.log("keycloak token: ", keycloakToken);
    //console.log("proposal process name: ", proposalProcessName);
    formData.append('proposalName', proposalProcessName)
    formData.append('file', file);


    try {

        const saveResponse =  await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
            method:'POST',
            headers: {
                Authorization: `Bearer ${keycloakToken}`,
            },
            body: formData,
        });

        if (!saveResponse.ok) {
            throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        //console.log("File saved with 'matrix' tag, continuing.");

    } catch (error) {
      //console.error('Error uploading files:', error);
    }

    setIsProcessing(false);
    setComplianceMatrixStreaming(true);
  };
  const saveExcelFileToDBImport = async (markdownStringImport) => {
    //console.log("in savetodexcel: ", markdownStringImport);
    const markdownTable = markdownStringImport; // Assuming complianceMatrix contains your markdown table string
    const lines = markdownTable.trim().split('\n');
    const headers = lines[0].split('|').slice(1, -1).map(header => header.trim());
    const dataRows = lines.slice(2);

    // Parse rows into objects
    const dataObjects = dataRows.map(row => {
      const cells = row.split('|').slice(1, -1).map(cell => cell.trim());
      return cells.reduce((obj, cell, index) => {
        obj[headers[index]] = cell;
        return obj;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(dataObjects);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ComplianceMatrix");

    // Instead of writing to file, write to binary string and convert to Blob
    const wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});
    let buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
    let view = new Uint8Array(buf);  //create uint8array as viewer
    for (let i=0; i<wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF; //convert to octet

    // Create Blob from ArrayBuffer
    const blob = new Blob([buf], {type:'application/octet-stream'});


    const excelFileName = `${proposalProcessName}-ComplianceMatrix.xlsx`;
    const file = new File([blob], excelFileName, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

    // Store the File object in the state
    setExcelFileSelected(file);
    const formData = new FormData();
    formData.append('productFile', file);
    formData.append('productName', proposalProcessName);
    //console.log("selected file - ", file);

    // Include any additional data as needed
    formData.append('tag', 'matrix');
    formData.append('userID', userID);
    //console.log("keycloak token: ", keycloakToken);
    //console.log("proposal process name: ", proposalProcessName);
    formData.append('proposalName', proposalProcessName)
    formData.append('file', file);


    try {

        const saveResponse =  await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
            method:'POST',
            headers: {
                Authorization: `Bearer ${keycloakToken}`,
            },
            body: formData,
        });

        if (!saveResponse.ok) {
            throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        //console.log("File saved with 'matrix' tag, continuing.");

    } catch (error) {
      //console.error('Error uploading files:', error);
    }

    setIsProcessing(false);
    setComplianceMatrixStreaming(true);
  };


  const handleExportToExcel = () => {
      const markdownTable = complianceMatrix;
      const lines = markdownTable.trim().split('\n');
      const headers = lines[0].split('|').slice(1, -1).map(header => header.trim());

      // Skip the markdown table's header and separator lines for data rows
      const dataRows = lines.slice(2).map(row => {
          const cells = row.split('|').slice(1, -1).map(cell => cell.trim());
          return headers.reduce((obj, header, i) => {
              obj[header] = cells[i];
              return obj;
          }, {});
      });

      // Initialize an empty worksheet
      const ws = XLSX.utils.json_to_sheet([]);

      // Adding the title and ensuring it spans the correct range
      XLSX.utils.sheet_add_json(ws, [{"Compliance Matrix": "A"}], {origin: "A1"});
      ws["!merges"] = [{s: {r: 0, c: 0}, e: {r: 0, c: headers.length - 1}}]; // Ensure merge spans correct columns

      // Manually setting headers in the next row (Excel row 2)
      const headerRow = XLSX.utils.encode_row(1); // Excel rows are 1-indexed
      headers.forEach((header, i) => {
          const cellRef = XLSX.utils.encode_cell({r: 1, c: i});
          ws[cellRef] = {t: 's', v: header};
      });

      // Adding data rows, starting below the headers
      XLSX.utils.sheet_add_json(ws, dataRows, {origin: "A2"});

      // Set column widths as needed
      ws['!cols'] = [{wch: 10}, {wch: 100}, {wch: 50}, {wch: 20}, {wch: 12}];
      // After adding data to the worksheet (ws)

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ComplianceMatrix");

      const excelFileName = proposalProcessName + "-ComplianceMatrix.xlsx";
      XLSX.writeFile(wb, excelFileName);
  };


  const processExcelBlobToMarkdown = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (evt) => {
        try {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

          let markdownString = `| RFP Reference | Requirement Detail | Compliance Method | Requirement Category | Status/Action Needed |\n`;
          markdownString += `|---------------|--------------------|-------------------|----------------------|----------------------|\n`;

          // Assuming data[0] contains headers and the rest are data rows
          data.slice(1).forEach(row => {
            markdownString += `| ${row.join(" | ")} |\n`;
          });

          resolve(markdownString);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => reject(error);

      reader.readAsBinaryString(blob);
      //saveExcelFileToDB();
    });
  };
  const handleExcelFileChange = (e) => {
    const file = e.target.files[0]; // Get the file
    const reader = new FileReader();

    reader.onload = (evt) => {
      // evt.target.result contains file content
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // Convert array of arrays
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      //console.log(data); // data is now in a readable array format

      // Process the data...
      processExcelData(data);
    };

    reader.readAsBinaryString(file);
  };


  const processExcelData = (data) => {
    // Skip the header row and transform the rest into objects
    const items = data.slice(2).map(row => {
      return {
        rfpReference: row[0],
        requirementDetail: row[1],
        complianceMethod: row[2],
        requirementCategory: row[3],
        statusActionNeeded: row[4]
      };
    });

    let markdownString = `| RFP Reference | Requirement Detail | Compliance Method | Requirement Category | Status/Action Needed |\n`;
    markdownString += `|---------------|--------------------|-------------------|----------------------|----------------------|\n`;

    items.forEach(item => {
      markdownString += `| ${item.rfpReference} | ${item.requirementDetail} | ${item.complianceMethod} | ${item.requirementCategory} | ${item.statusActionNeeded} |\n`;
    });

    // Here, you could update your component's state with this markdown string,
    // or perform further actions with it
    //console.log(markdownString);

    setComplianceMatrix(markdownString);
    setComplianceMatrixStreamed(markdownString);
    setComplianceMatrixCreated(true);
    saveExcelFileToDBImport(markdownString);
  };
  function toRoman(num) {
    const lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1};
    let roman = '';
    for (let i in lookup ) {
      while ( num >= lookup[i] ) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }
  const handleViewEditDraftClick = (volumeIndex, volumeTag) => {
    // Convert volumeIndex to Roman numeral
    const volumeKey = toRoman(volumeIndex + 1); // Adding 1 because Roman numerals start from I, not 0
    //console.log(`View & Edit Draft clicked for volume index: ${volumeIndex}, Roman numeral: ${volumeKey}`);

    // Assuming updatedVolumes is an object with Roman numeral keys
    const selectedVolumeContent = draftVolumes[volumeKey];
    //console.log(`Selected volume content for ${volumeKey}:`, selectedVolumeContent);




    setOutlineHeadersGenerated(true); // Assuming this flag is necessary for your application logic

    if (selectedVolumeContent) {
      setOutlineFirstDraftMarkdown(selectedVolumeContent);
      setFirstDraftGenerated(true);
      const selectedVolumeTitle = proposalVolumes[volumeIndex].title;
      setOutlineTitle(selectedVolumeTitle);
      const selectedVolumeOutlineContent = proposalVolumes[volumeIndex].sections.join('\n\n');
      setOutlineContent(selectedVolumeOutlineContent); // Update the outline content with the selected volume's content
      //setOutlineContent(volume)
      setStreamedContent(selectedVolumeContent);
      setTagForDraft(volumeTag);
      setSelectedTab("viewFirstDraft");
    } else {
      //console.log(`No updated content found for volume index: ${volumeIndex}, Roman numeral: ${volumeKey}`);
    }



  };

  const removeVolume = (indexToRemove) => {
    //console.log(`Removing volume at index: ${indexToRemove}`);

    setProposalVolumes(currentVolumes => {
      //console.log('Current volumes before removal:', currentVolumes);

      const updatedVolumes = currentVolumes.filter((_, index) => index !== indexToRemove);

      //console.log('Volumes after removal:', updatedVolumes);
      handleResponseFromGenerateProposalStructure(updatedVolumes);
      return updatedVolumes;
    });
  };



  const handleReferenceFileChange = (fileName, isChecked) => {
      setSelectedRefFiles(prevSelectedRefFiles => ({
          ...prevSelectedRefFiles,
          [fileName]: isChecked,
      }));
  };

  const handleSelectAllReferenceFiles = (isChecked, allFiles) => {
      const updatedSelectedFiles = {};
      if (isChecked) {
          allFiles.forEach(file => {
              updatedSelectedFiles[file.name] = true; // Mark all files as selected
          });
      } // No need to do anything if unchecked; updatedSelectedFiles remains empty
      setSelectedRefFiles(updatedSelectedFiles);
  };
  const handleSelectAllForProduct = (isChecked, productName, files) => {
      const updatedSelectedRefFiles = { ...selectedRefFiles };
      files.forEach(file => {
          updatedSelectedRefFiles[file.name] = isChecked;
      });
      setSelectedRefFiles(updatedSelectedRefFiles);
  };

  const saveTitleChange = (event, volumeIndex) => {
    const originalTitle = proposalVolumes[volumeIndex].title; // Capture the original title for comparison
    const newTitle = event.target.innerText.trim();
    console.log(`Original title: ${originalTitle}`); // Log the original title
    console.log(`New title: ${newTitle}`); // Log the new, edited title

    if (originalTitle !== newTitle) { // Only update if there's a change
      const updatedVolumes = proposalVolumes.map((volume, idx) =>
        idx === volumeIndex ? { ...volume, title: newTitle } : volume
      );
      setProposalVolumes(updatedVolumes);
      console.log(`Title updated to: ${newTitle}`); // Confirm the update action

      // Call your function to regenerate/update the proposal structure
      handleResponseFromGenerateProposalStructure(updatedVolumes);
    } else {
      console.log("Title unchanged."); // Indicate no change was made
    }
  };

  const addNewVolume = () => {
      const newVolumeIndex = proposalVolumes.length + 1; // Determine the index for the new volume
      const newVolumeTag = `draft_volume_${toRoman(newVolumeIndex)}`; // Create the tag using the Roman numeral

      // Define the detailed example section to include in the new volume
      const exampleSectionText = `1. **Introduction and Background** - Compliance with sections {X}
     - **1.1 Subsection** - Compliance with section {X}`;

      const newVolume = {
          title: `Volume Title`, // Include the index in the title with Roman numeral
          sections: [exampleSectionText], // Include the detailed example section in the new volume
          tag: newVolumeTag,
      };

      const updatedVolumes = [...proposalVolumes, newVolume];
      setProposalVolumes(updatedVolumes); // Update the state with the new list of volumes

      // Call the function to handle the updated structure, ensuring the new volume and its section are processed
      handleResponseFromGenerateProposalStructure(updatedVolumes);
  };
























  return (
    <div className="dashboard">
        <Sidebar
          isSidebarCollapsed={isSidebarCollapsed}
          setSelectedTab={setSelectedTab}
          toggleSidebar={toggleSidebar}
          handleLogout={handleLogout}
          userInfo={userInfo}
          currentTab={selectedTab}
          steps={steps}
        />
        <div className={`dashboard-main-content ${isSidebarCollapsed ? 'sidebar-collapsed' : ''} ${isAISidebarOpen ? 'AIsidebar-open' : ''}`}>
            <h1>{proposalProcessName}</h1>
            <p className="app-version" style={{ position: 'fixed', bottom: '0', right: '0', margin: '10px 4px 0 10px', fontSize: '10px', opacity: '0.7' }}>Version 2.9.5</p>



            <StepIndicator steps={steps} currentStep={selectedTab} setSelectedTab={setSelectedTab} nextButtonActive={nextButtonActive} />



            {selectedTab === 'nameProposal' && (
             <>
              <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
                {currentStepIndex > 0 && (
                  <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
                )}
              
                {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                  <button onClick={goToNextStep} disabled={!nextButtonActive}>Next</button>
                )}
              </div>
              <div className="dashboard-console">
                <div className="manageDBTab">
                  <h1>Create a new workflow to start a fresh proposal process</h1>
                  <form className="proposal-process-form" onSubmit={handleProposalProcessSubmit}>
                    <input
                      type="text"
                      placeholder="Proposal Process Name (Ex: Name - Date - RFx)"
                      value={proposalProcessNameInText}
                      onChange={handleProposalProcessNameChange}
                      className="inputProductName"
                    />
                    <button type="submit" className="submitProposalName" disabled={!proposalProcessNameInText.trim()}>
                      Create New RFx Proposal Process
                    </button>
                  </form>
                </div>

                <button className="btn btn-sbir" onClick={() => handleNavigation('SBIR')}>
                  Working on a Grant?
                </button>

                <Modal
                  isOpen={isModalOpen}
                  onRequestClose={handleModalClose}
                  contentLabel="Select Proposal Type"
                  className="modal"
                  overlayClassName="overlay"
                >
                  <h2>Select Proposal Type</h2>
                  <p>Please select whether you will be working on a Grant or RFx proposal.</p>
                  <div className="modal-buttons">
                    <button className="btn btn-sbir" onClick={() => handleSelection('GRANT')}>Grant</button>
                    <button className="btn btn-rfp" onClick={() => handleSelection('RFx')}>RFx</button>
                  </div>
                </Modal>
              </div>
             </>
            )}
            {selectedTab === 'nameProposal' && (
              <div className="allProducts">
                <h2>Or select an existing proposal process flow to pick up where you left off</h2>
                <div className="product-list">
                  {Object.entries(proposalProcesses).map(([productName, files], index) => {
                    // Check if there is any JSON file in the files array
                    const hasJsonFile = files.some(file => file.name.endsWith('.json'));

                    return (
                      <div key={index} className={`product1 ${hasJsonFile ? 'product-json' : ''}`}>
                        {hasJsonFile && <div className="sbir-banner">SBIR</div>}
                        <h3>{productName}</h3>
                        <ul>
                          {files.map((file, fileIndex) => (
                            <li key={fileIndex}>
                              <div className="file-name-products" onClick={() => handleDownloadFromAllProductsDiv(file)}>
                                <i className="fas fa-download file-download-icon"></i> {/* Font Awesome download icon */}
                                {file.name}
                              </div>
                              <button className="file-remove-btn" onClick={() => handleRemoveProcessProposalFile(productName, file.name)}>
                                <i className="fas fa-times-circle"></i>
                              </button>
                            </li>
                          ))}
                        </ul>
                        {/* Add a button here to set the proposalProcessName to the current productName */}
                        <button onClick={() => handleProposalProcessContinueWork(productName)}>Continue Work</button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}


            {selectedTab === 'samOpportunities' && (
                <div className="sam-opportunities">
                    <h2>SAM Opportunities</h2>
                    {samOpportunities && samOpportunities.length > 0 ? (
                        <ul>
                            {samOpportunities.map((opportunity, index) => (
                                <li key={index}>
                                    <h3>{opportunity.title}</h3>
                                    <p>Solicitation Number: {opportunity.solicitationNumber}</p>
                                    <p>Posted Date: {opportunity.postedDate}</p>
                                    <p>Response Deadline: {opportunity.responseDeadLine}</p>
                                    <p>Organization: {opportunity.fullParentPathName}</p>
                                    <p>NAICS Code: {opportunity.naicsCode}</p>
                                    <p>Classification Code: {opportunity.classificationCode}</p>
                                    <p>Point of Contact: {opportunity.pointOfContact.map(poc => (
                                        <span key={poc.email}>{poc.fullName}, Email: {poc.email}, Phone: {poc.phone || 'N/A'}</span>
                                    ))}</p>
                                    <a href={opportunity.uiLink} target="_blank" rel="noopener noreferrer">View Opportunity</a>
                                    <button onClick={() => handleAddToPipeline(opportunity)}>Add to pipeline</button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No opportunities available.</p>
                    )}
                </div>
            )}

            {selectedTab === 'viewPipeline' && (
                <div className="sam-opportunities">
                    <h2>Pipeline Opportunities</h2>
                    {pipelineOpportunities && pipelineOpportunities.length > 0 ? (
                        <ul>
                            {pipelineOpportunities.map((opportunity, index) => (
                                <li key={index}>
                                    <h3>{opportunity.title}</h3>
                                    <p>Solicitation Number: {opportunity.solicitationNumber}</p>
                                    <p>Posted Date: {opportunity.postedDate}</p>
                                    <p>Response Deadline: {opportunity.responseDeadLine}</p>
                                    <p>Organization: {opportunity.fullParentPathName}</p>
                                    <p>NAICS Code: {opportunity.naicsCode}</p>
                                    <p>Classification Code: {opportunity.classificationCode}</p>
                                    <p>Point of Contact: {opportunity.pointOfContact.map(poc => (
                                        <span key={poc.email}>{poc.fullName}, Email: {poc.email}, Phone: {poc.phone || 'N/A'}</span>
                                    ))}</p>
                                    <p>Office Address: {opportunity.officeAddress.city}, {opportunity.officeAddress.state}, {opportunity.officeAddress.zipcode}, {opportunity.officeAddress.countryCode}</p>
                                    <a href={opportunity.uiLink} target="_blank" rel="noopener noreferrer">View Opportunity</a>
                                    <button onClick={() => handleRemoveFromPipeline(opportunity.solicitationNumber)}>Remove from pipeline</button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No opportunities in pipeline.</p>
                    )}
                </div>
            )}


                        {selectedTab === 'viewAnalysis' && (
                            <div className="analysis-container">
                                <div className="fpds-opportunities">
                                    <h2>Returned Response</h2>
                                    <div className="search-bar">
                                        <input
                                            type="text"
                                            value={searchKeyword}
                                            onChange={(e) => setSearchKeyword(e.target.value)}
                                            placeholder="Enter keyword"
                                        />
                                        <button onClick={handleSearch}>Search</button>
                                    </div>
                                    {isAnalysisLoading && <p>Loading...</p>}
                                    {analysisError && <p className="error">Error: {analysisError.message}</p>}
                                    {analysisXMLData && analysisXMLData.map((item, index) => (
                                        <div key={index} className="xml-item">
                                            <h3>{item.title}</h3>
                                            <p><strong>Modified:</strong> {item.modified}</p>
                                            <p><strong>Agency:</strong> {item.agency}</p>
                                            <p><strong>PIID:</strong> {item.PIID}</p>
                                            <p><strong>Signed Date:</strong> {item.signedDate}</p>
                                            <p><strong>Effective Date:</strong> {item.effectiveDate}</p>
                                            <p><strong>Current Completion Date:</strong> {item.currentCompletionDate}</p>
                                            <p><strong>Ultimate Completion Date:</strong> {item.ultimateCompletionDate}</p>
                                            <p><strong>Obligated Amount:</strong> {item.obligatedAmount}</p>
                                            <p><strong>Base and Exercised Options Value:</strong> {item.baseAndExercisedOptionsValue}</p>
                                            <p><strong>Base and All Options Value:</strong> {item.baseAndAllOptionsValue}</p>
                                            <p><strong>Department Name:</strong> {item.departmentName}</p>
                                            <p><strong>Description of Contract Requirement:</strong> {item.descriptionOfContractRequirement}</p>
                                            <p><strong>Product or Service Code:</strong> {item.productOrServiceCode}</p>
                                            <p><strong>Created By:</strong> {item.createdBy}</p>
                                            <p><strong>Created Date:</strong> {item.createdDate}</p>
                                            <p><strong>Last Modified By:</strong> {item.lastModifiedBy}</p>
                                            <p><strong>Last Modified Date:</strong> {item.lastModifiedDate}</p>
                                            <p><strong>Status:</strong> {item.status}</p>
                                            <p><strong>Approved By:</strong> {item.approvedBy}</p>
                                            <p><strong>Approved Date:</strong> {item.approvedDate}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}








            {selectedTab === 'manageDatabase' && proposalProcessName && (
              <>
                <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
                  {currentStepIndex > 0 && (
                    <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
                  )}

                  {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                    <button onClick={goToNextStep} disabled={!nextButtonActive}>Next</button>
                  )}
                </div>
                <div className="dashboard-console">
                  <div className="manageDBTab">
                    <h1>Upload new documents that are relevant to your proposal</h1>
                    <p style={{fontStyle: "italic"}}>These can include previous proposals, technical documents, or any other materials you want the AI to reference.</p>
                    <p>Make sure to organize your documents by name.</p>
                    {isAddingProduct && (
                      <div className="newProduct">
                        <input
                          type="text"
                          placeholder="Product Name"
                          value={productName}
                          onChange={handleProductNameChange}
                          className="inputProductName"
                        />
                        <div {...getRootProps()} className="dropzone">
                          <input {...getInputProps()} />
                          {
                            isDragActive ?
                              <p>Drop the files here ...</p> :
                              <p>Drag and drop some files here, or click to select files</p>
                          }
                        </div>
                        <ul>{filesList}</ul>
                        <button
                          onClick={handleUploadProductToDB}
                          disabled={(!productFiles || productFiles.length === 0) || productName == ''} // This disables the button if no files are uploaded
                        >
                          Upload Files to Database
                        </button> {/* Closing tag for <button> was missing */}
                      </div>
                )}

                {uploadSuccess && (
                  <div className="upload-success-message">
                    Files have been successfully uploaded.
                    <button onClick={handleAddAnotherProduct}>Add Another Product</button>
                  </div>
                )}
              </div>
              </div>
             </>
            )}
            {selectedTab === 'manageDatabase' && proposalProcessName && (
              <div className="allProducts">
                <h2>Existing Products that the AI has access to</h2>
                <p>Remove any outdated or irrelevant documents from the database to keep the AI's reference material as pertinent as possible.</p>
                <div className="product-list">
                  {Object.entries(products).map(([productName, files], index) => (
                    <div key={index} className="product">
                      <h3>{productName}</h3>
                      <ul>
                        {files.map((file, fileIndex) => (
                          <li key={fileIndex}>
                            <div className="file-name-products" onClick={() => handleDownloadFromAllProductsDiv(file)}>{file.name}</div>
                            <button onClick={() => handleRemoveFile(productName, file.name)}>Remove</button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {selectedTab === 'manageDatabase' && !proposalProcessName && (
              <div className="go-back-div">
                <h2>You must create a workflow or choose an existing workflow first.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('nameProposal')}>
                  Go to Select Workflow
                </button>
              </div>
            )}
            {selectedTab === 'generateProposal' && !proposalProcessName && (
              <div className="go-back-div">
                <h2>You must create a workflow or choose an existing workflow first.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('nameProposal')}>
                  Go to Select Workflow
                </button>
              </div>
            )}
            {selectedTab === 'viewComplianceMatrix' && (!complianceMatrixStreaming) && (
              <div className="go-back-div">
                <h2>You must upload an RFP/RFI/RFQ/SBIR and click create Compliance Matrix First.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('generateProposal')}>
                  Go to Upload Page
                </button>
              </div>
            )}
            {selectedTab === 'viewProposalStructure' && (!proposalStructureStreaming) && (
              <div className="go-back-div">
                <h2>You must go back and click the create proposal structure button.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('viewComplianceMatrix')}>
                  Go to Compliance Matrix Page
                </button>
              </div>
            )}
            {selectedTab === 'viewOutline' && !outlineContent && (
              <div className="go-back-div">
                <h2>You must go back and click a volume to edit.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('viewProposalStructure')}>
                  Go to proposal structure page
                </button>
              </div>
            )}
            {selectedTab === 'viewFirstDraft' && streamedContent == '' && (
              <div className="go-back-div">
                <h2>You must go back and click Create Draft Based on This Outline or select an already generated draft.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('viewOutline')}>
                  Go to outline editor
                </button>
              </div>
            )}


            {selectedTab === 'generateProposal' && proposalProcessName && (
            <>
                <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
                  {currentStepIndex > 0 && (
                    <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
                  )}

                  {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                    <button onClick={goToNextStep} disabled={!complianceMatrixStreaming || !Object.values(selectedRefFiles).some(isSelected => isSelected)}>Next</button>

                  )}
                </div>
                <div className="dashboard-console">
                  <div className="generateProposalTab">
                    <h1>Upload Solicitation</h1>

                    {/* Existing new proposal file upload */}
                    <div className="file-upload-container">
                      <label htmlFor="newProposalFile" className="file-upload-label">
                        Upload New RFQ/RFI/RFP PDF
                      </label>
                      <input
                        id="newProposalFile"
                        type="file"
                        accept=".pdf"
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Hide the actual input
                      />
                      {selectedFile && (
                        <div className="file-info">
                            <p>Selected file: {selectedFile.name} <button onClick={removeSelectedFile} className="remove-file-button">X</button></p>

                        </div>
                      )}
                    </div>

                    <div>
                        <h2>Select Reference Files</h2>
                        <p>This section allows you to select specific files from your database that you want the AI to reference when creating a draft for this solicitation. Choose from the list below to ensure the AI utilizes the most relevant information for drafting your proposal.</p>

                        <div className={`dropdown-container`}>
                            <div className="dropdown-check-list">
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={e => handleSelectAllReferenceFiles(e.target.checked, [].concat(...Object.values(products)))}
                                        checked={Object.keys(selectedRefFiles).length === [].concat(...Object.values(products)).length}
                                    /> Select All Files
                                </label>
                                {Object.entries(products).map(([productName, files], index) => (
                                    <div key={index} className="product-group">
                                        <label className="product-name-label">
                                            <input
                                                type="checkbox"
                                                onChange={e => handleSelectAllForProduct(e.target.checked, productName, files)}
                                                checked={files.every(file => selectedRefFiles[file.name])}
                                            />
                                            {productName}
                                        </label>
                                        {files.map((file, fileIndex) => (
                                            <label key={fileIndex} className="file-label">
                                                <input
                                                    type="checkbox"
                                                    name={file.name}
                                                    checked={!!selectedRefFiles[file.name]}
                                                    onChange={e => handleReferenceFileChange(file.name, e.target.checked)}
                                                /> {file.name}
                                            </label>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>









                    <button onClick={handleFileUpload} disabled={!selectedFile || isProcessing} style={{display: "none"}}>
                      {isProcessing ? 'Extracting Requirements from file... Your page will update automatically once the AI begins generating.' : 'Generate Outline for Single Volume Proposal'}
                    </button>
                    <GenerateComplianceMatrix
                      onMatrixCreated={handleResponseFromGenerateComplianceMatrix}
                      currentRFP={selectedFile}
                      apiKey={apiKey}
                      setSelectedTab={setSelectedTab}
                      setComplianceMatrixStreamed={setComplianceMatrixStreamed}
                      selectedFile={selectedFile}
                      handleFileUploadMatrix={handleFileUploadMatrix}
                      setComplianceMatrixStreaming={setComplianceMatrixStreaming}
                      complianceMatrixStreaming={complianceMatrixStreaming}
                      selectedRefFiles={selectedRefFiles}
                    />
                    {outlineHeadersGenerated && (
                      <button onClick={() => setSelectedTab("viewOutline")}>
                        View your generated outline
                      </button>
                    )}

                  </div>
                  </div>
            </>
            )}

          {selectedTab === 'viewOutline' && outlineContent && (
          <>
            <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
              {currentStepIndex > 0 && (
                <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
              )}

              {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                <button onClick={goToNextStepOutlineToDraft} disabled={outlineFirstDraftMarkdown == ''}>Next</button>
              )}
            </div>
            <LexicalComposer initialConfig={initialConfig}>
                <div className="editor-shell">
                  <Editor
                    isAICollapsed={isAICollapsed}
                    setIsAICollapsed={setIsAICollapsed}
                    token={kc.token}
                    proposalProcessName={proposalProcessName}
                    tag={tagForDraft+"_outline"}
                    handleResponseFromEditOutline={handleResponseFromEditOutline}
                  />
                </div>
                {outlineHeadersGenerated && <ConvertFirstDraftFromMarkdown outlineMarkdown={outlineContent} />}
                {outlineHeadersGenerated &&

                <GenerateFirstDraftWithLexical
                  onFirstDraftCreated={handleResponseFromGenerateFullOutline}
                  apiKey={apiKey}
                  setSelectedTab={setSelectedTab}
                  setStreamedContent={setStreamedContent}
                  companyName={companyName}
                  outlineHeaders={outlineContent} // This will pass the updated sections as outlineHeaders
                  setOutlineContent={setOutlineContent}
                  outlineTitle={outlineTitle}
                  setFirstDraftSteaming={setFirstDraftSteaming}
                  userID={userID}
                  volumeTag={tagForDraft}
                  handleResponseFromEditOutline={handleResponseFromEditOutline}
                  rfpFile={selectedFile}
                  numProposalsRFx={numProposalsRFx}
                  selectedRefFiles={selectedRefFiles}
                  groupId={groupId}
                />
                }
                <FloatingToolbar
                  isAICollapsed={isAICollapsed}
                  setIsAICollapsed={setIsAICollapsed}
                  onTextSelection={handleTextSelection}
                />
            </LexicalComposer>
          </>
          )}


          {selectedTab === 'viewFirstDraft' && firstDraftGenerated && (
          <>
            <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
              {currentStepIndex > 0 && (
                <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
              )}

              {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                <button onClick={goToNextStep} disabled={!nextButtonActive}>Next</button>
              )}
            </div>
            <LexicalComposer initialConfig={draftConfig}>
                <div className="editor-shell">
                  <Editor
                    isAICollapsed={isAICollapsed}
                    setIsAICollapsed={setIsAICollapsed}
                    token={kc.token}
                    proposalProcessName={proposalProcessName}
                    tag={tagForDraft}
                    handleResponseFromEditOutline={handleResponseFromSaveDraft}
                  />
                </div>
                {firstDraftGenerated && <ConvertFirstDraftFromMarkdown outlineMarkdown={outlineFirstDraftMarkdown} />}
                <FloatingToolbar
                  isAICollapsed={isAICollapsed}
                  setIsAICollapsed={setIsAICollapsed}
                  onTextSelection={handleTextSelection}
                />
            </LexicalComposer>
          </>
          )}
          {selectedTab === 'viewGenerationFirstDraft' && (
          <>
            <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
              {currentStepIndex > 0 && (
                <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
              )}

              <h2>Generated Proposal</h2>

              {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                <button onClick={goToNextStep} disabled={!firstDraftGenerated}>Next</button>
              )}
            </div>
            <div className="streamed-content-container" ref={streamedContentRef}>
              <div className="streamed-content"><ReactMarkdown>{streamedContent}</ReactMarkdown></div>
            </div>
          </>
          )}
          {selectedTab === 'viewGenerationOutline' && (
            <div className="streamed-content-container" ref={streamedContentRef}>
              <h2>Generated Outline</h2>
              <div className="streamed-content"><ReactMarkdown>{outlineContent}</ReactMarkdown></div>
            </div>
          )}






          {/*selectedTab === 'viewComplianceMatrix' && (complianceMatrixStreaming || complianceMatrixCreated) && (*/}
          {selectedTab === 'viewComplianceMatrix'&& (
          <>
              <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
                {currentStepIndex > 0 && (
                  <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
                )}

                <h2>Compliance Matrix</h2>

                {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                  <button onClick={goToNextStep} disabled={!proposalStructureStreaming}>Next</button>
                )}
              </div>

              {complianceMatrixCreated && (
                <div className="fade-in">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: '30px' }}>
                    <h3 style={{ margin: '0', position: 'relative' }}>Compliance Matrix Accuracy
                      <span style={{
                        position: 'absolute',
                        backgroundColor: '#FFD700',
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        padding: '2px 5px',
                        borderRadius: '5px',
                        top: '-20px',
                        right: '-20px'
                      }}>BETA</span>
                    </h3>
                    <div style={{ width: '50px', height: '50px' }}>
                      <CircularProgressbar
                        value={complianceMatrixAccuracy}
                        text={`${complianceMatrixAccuracy}%`}
                        styles={buildStyles({
                          textColor: 'blue',
                          pathColor: 'turquoise',
                          trailColor: 'silver',
                        })}
                      />
                    </div>
                  </div>
                  <p style={{ margin: '0', fontSize: '12px', paddingTop: '10px', width: '60%' }}>
                    {complianceMatrixSummary}
                  </p>
                </div>
              )}







            {/* Banner to display when this tab is selected */}
            {selectedTab === 'viewComplianceMatrix' && !complianceMatrixCreated && complianceMatrixStreaming && (
                <div id="banner" style={{ backgroundColor: 'lightblue', textAlign: 'center', padding: '10px', marginBottom: '20px', display: 'block' }}>
                    Compliance matrix is being generated, please allow up to 5 minutes.
                </div>
            )}

            <div className="streamed-content-container" ref={streamedContentRef}>
              <div className="compliance-matrix">
                <div className="streamed-content"><ReactMarkdown remarkPlugins={[remarkGfm]}>{complianceMatrixStreamed}</ReactMarkdown></div>
              </div>
            </div>
            <div className="compliance-matrix-btn-div">
                <GenerateProposalStructure
                  onProposalStructureCreated={handleResponseFromGenerateProposalStructure}
                  apiKey={apiKey}
                  currentComplianceMatrix={complianceMatrix}
                  setSelectedTab={setSelectedTab}
                  setProposalStructureStreamed={setProposalStructureStreamed}
                  complianceMatrixCreated={complianceMatrixCreated}
                  setProposalStructureStreaming={setProposalStructureStreaming}
                  selectedFile={selectedFile}
                />
                <button className="excel-export-button" onClick={handleExportToExcel} disabled={!complianceMatrixCreated}>Export to Excel</button>
                <input type="file" id="file" accept=".xlsx, .xls" onChange={handleExcelFileChange} style={{display: 'none'}} />
                <label htmlFor="file" className="btn btn-default">Import Excel</label>
            </div>

          </>
          )}

          {selectedTab === 'viewProposalStructure' && (proposalStructureStreaming || proposalStructureGenerated) && (
          <>
            <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
              {currentStepIndex > 0 && (
                <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
              )}

              <h2>Proposal Structure</h2>

              {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                <button onClick={goToNextStep} disabled={!outlineContent}>Next</button>
              )}
            </div>
            <div className="streamed-content-container-proposal-structure" ref={streamedContentRef}>
              <div className="streamed-content-proposal-structure" style={{ display: proposalStructureGenerated ? 'none' : 'block' }}>
                <ReactMarkdown>{proposalStructureStreamed}</ReactMarkdown>
              </div>
              <div className="proposal-volumes-div">
                {proposalVolumes.map((volume, volumeIndex) => {
                  const isEditable = editableVolumeIndex === volumeIndex;
                  const buttonText = isEditable ? "Save Sections" : "Edit Outline";
                  const volumeTag = volume.tag;
                  const romanVolumeIndex = toRoman(volumeIndex + 1); // Adding 1 because your keys start from I, not 0
                  const hasDraft = !!draftVolumes[romanVolumeIndex];


                  return (
                    <div key={volumeIndex} className="proposal-volume" style={{ display: proposalStructureGenerated ? 'flex' : 'none' }}>
                      {/* Make the title editable with a unique ID if necessary */}
                      <div className="proposal-volume-header">
                        <h3
                          contentEditable
                          suppressContentEditableWarning={true}
                          id={`title-${volumeIndex}`}
                          onClick={(e) => e.currentTarget.focus()}
                          onFocus={(e) => console.log(`Before edit: ${e.target.innerText}`)}
                          onBlur={(e) => saveTitleChange(e, volumeIndex)}
                          style={{ cursor: 'text' }}
                        >
                          {volume.title}
                        </h3>


                        <button className="proposal-volume-remove-btn" onClick={() => removeVolume(volumeIndex)} aria-label="Remove Volume">
                          X {/* You can replace this with an icon for better UI */}
                        </button>
                      </div>
                      <ul>
                        {volume.sections.map((section, sectionIndex) => (
                          <div key={sectionIndex} className="test2">
                            <div className="section-and-remove-btn">
                              <div
                                className="editable-content-structure"
                                contentEditable={isEditable}
                                // Assign a unique ID to each section for identification
                                id={`section-${volumeIndex}-${sectionIndex}`}
                              >
                                <ReactMarkdown>{section}</ReactMarkdown>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                      <div className="btn-wrapper-div-structure">
                        <button className={`toggle-editability-btn-${volumeIndex}`} onClick={() => toggleEditability(volumeIndex, volumeTag)}>
                          <FontAwesomeIcon icon={faEdit} /> {/* Replace Toggle Editability button with edit icon */}
                          {buttonText}
                        </button>
                        <GenerateFirstDraft
                          onFirstDraftCreated={handleResponseFromGenerateFullOutline}
                          apiKey={apiKey}
                          setSelectedTab={setSelectedTab}
                          setStreamedContent={setStreamedContent}
                          companyName={companyName}
                          outlineHeaders={volume.sections} // This will pass the updated sections as outlineHeaders
                          setOutlineContent={setOutlineContent}
                          outlineTitle={volume.title}
                          proposalProcessName={proposalProcessName}
                          setFirstDraftSteaming={setFirstDraftSteaming}
                          userID={userID}
                          volumeTag={volumeTag}
                          rfpFile={selectedFile}
                          numProposals={numProposals}
                          selectedRefFiles={selectedRefFiles}
                          groupId={groupId}
                        />
                        <button onClick={() => handleViewEditDraftClick(volumeIndex, volumeTag)} disabled={!hasDraft}>
                          View & Edit Draft
                        </button>

                      </div>
                    </div>
                  );
                })}

                {proposalStructureGenerated && (
                  <button
                    onClick={addNewVolume}
                    style={{
                      width: 'auto',
                      height: '50px', // Set the height of the button to 50px
                      alignSelf: 'center' // Align the button vertically to the center of its container
                    }}
                  >
                    Add Volume
                  </button>
                )}


              </div>

            </div>
          </>
          )}


          {selectedTab === 'viewHowItWorks' && (
            <HowToUseGovExAI />
          )}
          {selectedTab === 'viewReferral' && (
            <ReferralPage referralCode={referralCode} numReferrals={numReferrals} />
          )}







        </div>






        {(selectedTab === 'viewOutline' || selectedTab === 'viewFirstDraft') && (
          <AISidebar
            isOpen={isAISidebarOpen}
            onClose={() => setIsAISidebarOpen(false)}
            onTextChange={(text) => setSelectedText(text)}
            text={selectedText}
            isAICollapsed={isAICollapsed}
            setIsAICollapsed={setIsAICollapsed}
          />
        )}
    </div>
  );
}

export default Dashboard;
