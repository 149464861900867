import React, { useState, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import Modal from 'react-modal';
import './GenerateProposalStructureSBIR.css'; // Import the CSS file

Modal.setAppElement('#root');

function GenerateProposalStructureSBIR({ stage, onProposalStructureCreated, apiKey, setSelectedTab, setProposalStructureStreamed, complianceMatrixCreated, setProposalStructureStreaming, selectedFile, topics, setIsGeneratingStructure, setCurrentStructureStep }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keywords, setKeywords] = useState("Technical Volume Content Requirements, Concept Paper");
  const loadingBarRef = useRef(null);

  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;
  console.log(topics);

  const handleGenerateProposalStructure = async () => {
    const outlineKeywords = keywords.split(',').map(keyword => keyword.trim());

    setIsGeneratingStructure(true);
    setCurrentStructureStep(0);
    setIsLoading(true);

    setProposalStructureStreamed(''); // Resetting streamed content state
    setProposalStructureStreaming(true);
    if (loadingBarRef.current) {
      loadingBarRef.current.continuousStart(); // Start loading animation
    }

    try {
      const formData = new FormData();
      formData.append('selectedFile', selectedFile);
      formData.append('stage', stage); // Include the stage in the form data
      if (Array.isArray(topics) && topics.length > 0) {
        topics.forEach(topic => {
          if (topic.topic_area) {
            formData.append('topicAreas', `${topic.topic_area} - ${topic.description}`);
            console.log(`Appended Topic: ${topic.topic_area} - ${topic.description}`);
          }
          if (topic.subtopic_area) {
            formData.append('subtopicAreas', `${topic.subtopic_area} - ${topic.description}`);
            console.log(`Appended Subtopic: ${topic.subtopic_area} - ${topic.description}`);
          }
        });
      } else {
        console.error('No topics selected.');
      }

      outlineKeywords.forEach(keyword => formData.append('outlineKeywords', keyword));

      const response = await fetch(`${BASE_URL}/govex/generate-proposal-structure-sbir`, {
        method: 'POST',
        headers: {
          'X-API-KEY': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Response was not ok: ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let proposalStructureContent = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          console.log("Streaming complete");
          break;
        }
        const chunk = decoder.decode(value, { stream: true });
        proposalStructureContent += chunk;
        setProposalStructureStreamed((prevContent) => prevContent + chunk); // Dynamically update the proposal structure content
      }

      console.log(proposalStructureContent);
      console.log("IN GENERATE PROPOSAL STRUCTURE SBIR!");
      const finalizeResponse = await fetch(`${BASE_URL}/govex/finalize-proposal-structure2`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ finalizedProposalStructure: proposalStructureContent, stage }), // Include the stage in the request body
      });

      console.log("Fetch request sent.");

      if (!finalizeResponse.ok) {
        throw new Error(`Finalize response was not ok: ${finalizeResponse.statusText}`);
      }

      const finalizeData = await finalizeResponse.json();
      console.log(stage);
      onProposalStructureCreated(finalizeData.finalizedProposalStructure, stage);

    } catch (error) {
      console.error('Error generating proposal structure:', error);
      setIsGeneratingStructure(false);
      setCurrentStructureStep(0);
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // End loading animation
      }
      setIsLoading(false); // Reset loading state
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    closeModal();
    handleGenerateProposalStructure();
  };

  return (
    <div>
      <button 
        onClick={openModal} 
        disabled={isLoading || !complianceMatrixCreated || topics.length === 0} 
        style={{ backgroundColor: (topics.length === 0 ? '#f8d7da' : ''), color: (topics.length === 0 ? '#721c24' : ''), cursor: (topics.length === 0 ? 'not-allowed' : '') }}
      >
        {isLoading ? 'Generating...' : (topics.length === 0 ? 'Please select a topic before continuing' : 'Generate Proposal Structure')}
      </button>
  
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Enter Keywords"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Enter Outline Keywords</h2>
        <p>Please enter the outline keywords separated by commas. <br/> <br/> These keywords will be used to find the relevant outlines in the SBIR document if applicable.<br/> <br/> If there isn't a table of contents provided in the solicitation, leave this blank. <br/> <br/> For example: <strong>Technical Volume, Concept Paper</strong></p>
        <textarea
          placeholder={keywords}
          onChange={(e) => setKeywords(e.target.value)}
          rows="4"
        />
        <div className="modal-buttons">
          <button className="btn btn-cancel" onClick={closeModal}>Cancel</button>
          <button className="btn btn-submit" onClick={handleSubmit}>Generate Proposal Structure</button>
        </div>
      </Modal>
    </div>
  );
  
}

export default GenerateProposalStructureSBIR;
